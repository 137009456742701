<div class="create_content">

  <div class="create_content_left">

    <form id="createPost" [formGroup]="postForm" (dragover)="onDocumentDragOver($event)" (drop)="onDrop($event)"
      (dragleave)="onDragLeave($event)">


      <!-- <div class="form_input_wrp">
        <div class="title_sec">
          <h5 class="text-24">Tagging</h5>
        </div>
        <div class="tabbing_input">

          <textarea [mentionConfig]="{items:items, labelKey:'name'}"
          [mentionListTemplate]="mentionListTemplate"></textarea>

          <ng-template #mentionListTemplate let-item="item">
            <img class="mr-3" src="https://avatars1.githubusercontent.com/u/{{item.img}}?s=180&v=4" alt="" width="30" height="30">
            &nbsp;
            <span>{{item.name}}</span>
          </ng-template>
        </div>
      </div> -->



      <!--<div class="form_input_wrp" *ngIf="!user.is_super_admin && !id">-->
      <div class="form_input_wrp intro_select_team" *ngIf="!id && sharedService.currentPlan.plan_id!== 'plan_1'"
        joyrideStep="post_team" stepPosition="bottom" [stepContent]="customContent">
        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/choose_the_team.svg" alt="">
            </div>
            <div class="detail_sec">
              <p>Here you can choose the team you'd like to share and work on this post with. O4A will start with your
                default team.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>
        <div class="title_sec">
          <div>
            <h5 class="text-24">Teams</h5>
            <span class="text-16">Please select the team you desire!</span>
          </div>
        </div>
        <div>
          <nz-form-item class="post_to_input cp_team_wrp">
            <nz-form-control>
              <nz-select [nzCustomTemplate]="cp_team" formControlName="team" [(ngModel)]="selectedTeam"
                [nzPlaceHolder]="'select the team'" (ngModelChange)="updateTeams($event)"
                [nzDropdownClassName]="ddTeamClass" (nzOpenChange)="sharedService.openNzSelect($event)">

                <nz-option *ngFor="let team of teams" nzCustomContent [nzValue]="team">
                  <div class="cp_team_main">
                    <div class="img_sec">
                      <app-image-preview [imageUrl]="team?.icon" [organizationProfile]="true"
                        [class]="'organization-list-profile'" [isVideo]="false">
                      </app-image-preview>
                    </div>
                    <div class="content_sec">
                      <h5>{{team.team_name}}</h5>
                    </div>
                  </div>
                </nz-option>
              </nz-select>
            </nz-form-control>
            <ng-template #cp_team let-selected>
              <div class="ant-select-selection-item-content">
                <div class="cp_team_main">
                  <div class="img_sec">
                    <app-image-preview [imageUrl]="selected.nzValue?.icon" [organizationProfile]="true"
                      [class]="'organization-list-profile'" [isVideo]="false">
                    </app-image-preview>
                  </div>
                  <div class="content_sec">
                    <h5>{{selected.nzValue?.team_name}}</h5>
                  </div>
                </div>
              </div>
            </ng-template>
          </nz-form-item>
        </div>
      </div>

      <div class="form_input_wrp intro_post_to" *ngIf="!id" joyrideStep="post_postTo" stepPosition="bottom"
        [stepContent]="customContent">
        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/selection_icon.svg" alt="">
            </div>
            <div class="detail_sec">
              <p>Select your preferred social media platforms to publish this post. Share across multiple accounts for
                maximum exposure.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>


        <div class="title_sec">
          <div>
            <h5 class="text-24">Post to</h5>
            <span class="text-16">Select one or more platforms (Facebook,Instagram etc)</span>
          </div>
        </div>

        <div>
          <!--<span *ngIf="selectedAccounts.length>0" (click)="clearAllAccounts()">Clear all</span>-->
          <nz-form-item class="post_to_input">
            <nz-form-control>
              <nz-select [nzDropdownRender]="renderTemplateAccountConnect"
                (nzOpenChange)="sharedService.openNzSelect($event)" nzDropdownClassName="post_accounts"
                [nzOptionOverflowSize]="10" [nzCustomTemplate]="multipleTemplate" formControlName="accounts_id"
                [nzShowArrow]="true" nzMode="multiple"
                [nzPlaceHolder]="isLoadingPost ? 'Accounts Loading..' : 'Select Account(s)'"
                (ngModelChange)="updateAccounts($event)"
                [nzDisabled]="isLoading || isLoadingPost || this.selectedTeam == undefined">
                <!-- [nzOptionHeightPx]="50" -->
                <!-- [nzOptiNonHeightPx]="55" -->

                <nz-option *ngFor="let account of accounts" nzCustomContent [nzLabel]="account.name"
                  [nzValue]="account">
                  <div class="post_social_option">
                    <img src="./assets/images/Twitter.png" class="icon"
                      *ngIf="account.provider === sharedService.twitterProvider" alt="Twitter Logo" />
                    <img src="./assets/images/LinkedIn.png" class="icon"
                      *ngIf="account.provider === sharedService.linkedinProvider" alt="LinkedIn Logo" />
                    <img src="./assets/images/Facebook.png" class="icon"
                      *ngIf="account.provider === sharedService.facebookProvider" alt="Facebook Logo" />
                    <img src="./assets/images/Instagram.png" class="icon"
                      *ngIf="account.provider === sharedService.instagramProvider" alt="Instagram Logo" />
                    <img src="./assets/images/YouTube.png" class="icon"
                      *ngIf="account.provider === sharedService.youtubeProvider" alt="YouTube Logo" />
                    <img src="./assets/images/tiktok.svg" class="icon"
                      *ngIf="account.provider === sharedService.tiktokProvider" alt="TikTok Logo"/>
                    <img src="./assets/images/Pinterest.png" class="icon"
                      *ngIf="account.provider === sharedService.pinterestProvider" alt="Pinterest Logo" />
                    <img src="./assets/images/threads.png" class="icon"
                      *ngIf="account.provider === sharedService.threadsProvider" alt="Threads Logo" />
                      <img src="./assets/images/snapchat.png" class="icon"
                      *ngIf="account.provider === sharedService.snapchatProvider" alt="Snapchat Logo" />
                    <span>{{ account.name }}</span>
                    <span
                      *ngIf="account.provider === sharedService.facebookProvider && account.type === sharedService.group">
                      ({{account.type}}
                      )</span>
                  </div>
                </nz-option>
              </nz-select>
              <ng-template #renderTemplateAccountConnect>
                <!-- <nz-divider></nz-divider> -->
                <div class="container select_input_container" *ngIf="sharedService.onlyAccessAdmin()">
                  <span class="btn_add_social" (click)="connectNewAccount()">
                    <span nz-icon nzType="plus" nzTheme="outline"></span>
                    Add a social account
                  </span>
                </div>
              </ng-template>

            </nz-form-control>
            <ng-template #multipleTemplate let-selected>
              <div class="ant-select-selection-item-content">
                <img src="./assets/images/Twitter.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.twitterProvider" alt="Twitter Logo" />
                <img src="./assets/images/LinkedIn.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.linkedinProvider" alt="LinkedIn Logo" />
                <img src="./assets/images/Facebook.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.facebookProvider" alt="Facebook Logo" />
                <img src="./assets/images/Instagram.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.instagramProvider" alt="Instagram Logo" />
                <img src="./assets/images/YouTube.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.youtubeProvider" alt="YouTube Logo" />
                <img src="./assets/images/tiktok.svg" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.tiktokProvider" alt="TikTok Logo"/>
                <img src="./assets/images/threads.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.threadsProvider" alt="Threads"/>
                <img src="./assets/images/Pinterest.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.pinterestProvider" alt="Pinterest Logo" />
                  <img src="./assets/images/snapchat.png" class="icon"
                  *ngIf="selected.nzValue.provider === sharedService.snapchatProvider" alt="Snapchat Logo" />
                <span>{{ selected.nzLabel }} {{ selected.nzValue.provider === sharedService.facebookProvider &&
                  selected.nzValue.type === sharedService.group ? '(' + selected.nzValue.type + ')' : '' }}</span>
              </div>
            </ng-template>
          </nz-form-item>

          <!-- Connect Accounts modal-->
          <nz-modal [(nzVisible)]="isAccountConnectModal" nzTitle="Connect Accounts" [nzFooter]="null"
            (nzOnCancel)="isAccountConnectModal = false" nzMaskClosable="false" [nzClassName]="'account_connect_modal'">
            <ng-container *nzModalContent>
              <div class="account_permissions_body app_account_connect_main">
                <!-- Start Connected Accounts -->
                <app-account-connect [isCreatePost]="true"></app-account-connect>
                <!-- End Connected Accounts -->
              </div>
            </ng-container>
          </nz-modal>

        </div>
      </div>

      <div class="form_input_wrp form_post_desc intro_description" joyrideStep="post_description" stepPosition="right"
        [stepContent]="customContent">
        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/get_creative.png" alt="">
            </div>
            <div class="detail_sec">
              <p>Get creative and write your post. Make sure to include relevant links, and don't forget to use the
                #Auto Hashtags button to generate #hashtags to reach a wider audience. Writer’s block? Use the “Write
                for Me” button to help write your post.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>


        <div class="title_sec description_title_sec">
          <div>
            <h5 class="text-24">Description</h5>
            <span class="text-16">Enter your post text, or click the 'Write for me' button to open ChatGPT.</span>
          </div>

          <div class="content-length">
            <ul>
              <li>
                {{ postForm?.get("content")?.value === null ? 0 : tweetWordCount(postForm?.get("content")?.value) }}
              </li>
              <ng-container *ngIf="allAccountProvider.length > 0">
                <span>/</span>
                <ng-container *ngFor="let accProvider of allAccountProvider">
                  <li *ngIf="accProvider === sharedService.twitterProvider">
                    <img src="assets/images/Twitter.png" class="icon" alt="Twitter Logo" />
                    <span [class.warning-color]="postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.twitterProvider) -
                              postForm.get('content')?.value?.trimStart().length <= 0">
                      {{ this.sharedService.getContentLength(sharedService.twitterProvider) -
                      postForm.get("content")?.value?.trimStart().length }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.linkedinProvider">
                    <img src="assets/images/LinkedIn.png" alt="LinkedIn Logo" class="icon" />
                    <span [class.warning-color]="
                                postForm.get('content')?.value !== null &&
                                sharedService.getContentLength(sharedService.linkedinProvider) -
                                postForm.get('content')?.value?.trimStart().length <= 0">
                      {{
                      this.sharedService.getContentLength(sharedService.linkedinProvider) -
                      postForm.get("content")?.value?.trimStart().length
                      }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.facebookProvider">
                    <img src="assets/images/Facebook.png" alt="Facebook Logo" class="icon" />
                    <span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.facebookProvider) -
                                postForm.get('content')?.value?.trimStart().length <= 0">
                      {{
                      this.sharedService.getContentLength(sharedService.facebookProvider) -
                      postForm.get("content")?.value?.trimStart().length
                      }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.instagramProvider">
                    <img src="assets/images/Instagram.png" alt="Instagram Logo" class="icon" />
                    <span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.instagramProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.instagramProvider) -
                      postForm.get("content")?.value?.trimStart().length
                      }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.youtubeProvider">
                    <img src="assets/images/YouTube.png" alt="YouTube Logo" class="icon" />
                    <span [class.warning-color]="postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.youtubeProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.youtubeProvider) -
                      postForm.get("content")?.value?.trimStart().length
                      }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.pinterestProvider">
                    <img src="assets/images/Pinterest.png" alt="Pinterest Logo" class="icon" />
                    <span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.pinterestProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.pinterestProvider) -
                      postForm.get("content")?.value?.trimStart().length
                      }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.tiktokProvider">
                    <img src="assets/images/tiktok.svg" class="icon" />
                    <span [class.warning-color]="
                              postForm.get('content')?.value !== null &&
                              this.sharedService.getContentLength(sharedService.tiktokProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{
                      this.sharedService.getContentLength(sharedService.tiktokProvider) -
                      postForm.get("content")?.value?.trimStart().length
                      }}
                    </span>
                  </li>
                  <li *ngIf="accProvider === sharedService.threadsProvider">
                    <img src="assets/images/threads.png" class="icon" />
                    <span [class.warning-color]="postForm.get('content')?.value !== null && this.sharedService.getContentLength(sharedService.threadsProvider) -
                                postForm.get('content')?.value?.trimStart().length <=0">
                      {{ this.sharedService.getContentLength(sharedService.threadsProvider) - postForm.get("content")?.value?.trimStart().length}}
                    </span>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="form_input_in">

          <div class="post_text_input">
            <!--[mentionConfig]="{items:items, labelKey:'name',mentionSelect: mentionSelect }" [mentionListTemplate]="mentionListTemplate"-->

            <textarea class="content" nz-input placeholder="Start to Write" formControlName="content" (keyup)="setValidation()" #inputContent
                      [class.disabled_while_loading]="schedulePostDetailLoading || isLoading"></textarea>

            <div class="post_input_action">
              <div class="post_input_action_left">

                <button class="btn_primary" (click)="writeForMe()" [disabled]="selectedAccounts.length===0" nz-tooltip
                  [nzTooltipTitle]="selectedAccounts.length===0 ? 'Please select an account before creating any post' : ''">
                  <!-- *ngIf="sharedService?.currentPlan?.plan_id=== 'plan_3'" -->
                  <svg fill="#ffffff" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
                    <title>OpenAI icon</title>
                    <path
                      d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
                  </svg>
                  <span>Write for Me</span>
                </button>

                <nz-drawer [nzClosable]="false" [nzVisible]="isWriteForMe" nzPlacement="right"
                  nzWrapClassName="wfm_drawer">
                  <ng-container *nzDrawerContent>
                    <div class="wfm_header">
                      <div class="wfm_header_left">
                        <h3>Write for Me</h3>
                      </div>
                      <div class="wfm_header_right">
                        <div class="powred_by">
                          <svg fill="#262C6C" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
                            <title>
                              OpenAI icon</title>
                            <path
                              d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
                          </svg>
                          <div class="powred_by_dtl">
                            <span>Powered by</span>
                            <h4>ChatGPT</h4>
                          </div>
                        </div>
                        <a href="javascript:;" class="close_wfm_drawer" (click)="closeWfmDrawer()">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                              fill="#202449"></path>
                          </svg>
                        </a>
                      </div>
                    </div>

                    <div class="wfm_body">
                      <div class="wfm_form" *ngIf="articles.length===0">
                        <ng-container *ngIf="total_chatgpt_article_limit !== -1">
                          <div class="wfm_warning" [class.noplan]="remaining_chatgpt_article_limit === 0">
                            <div class="wfm_warning_chart">
                              <ng-container *ngIf="remaining_chatgpt_article_limit > 0;else noPlan">
                                <nz-progress [nzStrokeColor]="'#423fd1'" nzType="circle" [nzFormat]="formatProgress"
                                  [nzPercent]="getPercentage()" [nzWidth]="70"></nz-progress>
                              </ng-container>
                              <ng-template #noPlan>
                                <svg fill="#262C6C" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
                                  <title>OpenAI icon</title>
                                  <path
                                    d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z"
                                    style="
                                      fill: #fd0013ab;
                                  "></path>
                                </svg>
                              </ng-template>
                            </div>
                            <div class="wfm_warning_msg">
                              <label *ngIf="remaining_chatgpt_article_limit === 0">You have reached the prompt limit for
                                this month. Buy Addon to get more prompts.</label>
                              <label *ngIf="remaining_chatgpt_article_limit > 0">Remaining number of prompts you can use
                                this month</label>
                            </div>
                          </div>
                        </ng-container>
                        <!-- <div class="wfm_form_in">
                          <label>What type of content are you looking to create?</label>
                          <nz-select nzPlaceHolder="Facebook post, Twitter post, Insta caption, etc."
                            [(ngModel)]="selectedPlatformText" [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let platformText of platformTexts" [nzLabel]="platformText"
                              [nzValue]="platformText"></nz-option>
                          </nz-select>
                        </div> -->
                        <div class="wfm_form_in">
                          <label>
                            What are the main points or ideas you would like to cover in your post?
                            <span nz-icon nzType="info-circle" nzTheme="fill" nz-tooltip nzTooltipTitle="For ChatGPT, the more detail you give it on what you want, the better it will do. If you want to limit character count, give it that as part of the prompt. Example: Tell me a knock knock joke in 200 characters or less."></span>
                          </label>
                          <textarea nz-input placeholder="Describe up to 3 key points for your post. Be sure to add the character limit for the target platform(s) (X, TikTok, etc.). The more descriptive you are, the more effective your post will be." [(ngModel)]="articleText"
                            [ngModelOptions]="{standalone: true}"></textarea>

                          <span class="wfm_count">{{articleText.length + '/' + articleTextLimit}}</span>
                          <span class="wfm_content_error" *ngIf="articleText.length>articleTextLimit">
                            Text description exceeds the limit of {{articleTextLimit}} characters. Please shorten your
                            input and try again.
                          </span>

                        </div>
                        <div class="wfm_form_in">
                          <label>What type of content are you looking to create?</label>
                          <nz-select nzPlaceHolder="😄 Positive & Upbeat" nzDropdownClassName="tone_of_voice_main"
                            [(ngModel)]="selectedTone" [nzCustomTemplate]="defaultTemplate"
                            [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let tone of toneTypes" nzCustomContent [nzLabel]="tone" [nzValue]="tone">
                              <div class="tone_of_voice">
                                <span>{{tone}}</span>
                              </div>
                            </nz-option>
                          </nz-select>

                          <ng-template #defaultTemplate let-selected>
                            {{ selected.nzLabel }}
                          </ng-template>
                        </div>
                        <div class="wfm_form_in">
                          <ng-container *ngIf="remaining_chatgpt_article_limit !== 0 else subscription">
                            <button class="btn_primary"
                              [disabled]="isArticleLoading || articleText.length==0 || articleText.length>articleTextLimit"
                              (click)="getArticles()">
                              Write Now
                            </button>
                          </ng-container>
                          <ng-template #subscription>
                            <button class="btn_primary" *ngIf="!sharedService.currentSubscription?.is_trial"
                              (click)="isPaymentModalVisible=true">
                              Buy Addon
                            </button>
                          </ng-template>
                        </div>
                      </div>

                      <div class="wfm_loader" *ngIf="isArticleLoading">
                        <video playsinline="" autoplay="" muted="" loop="">
                          <source src="../../../assets/images/wfm_loader.mp4" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>

                      <div class="writing_list" *ngIf="!isArticleLoading">
                        <div class="writing_list_in" *ngFor="let article of articles">

                          <ng-container *ngIf="article.text.length>0">
                            <div class="content_dtl">
                              <p>{{article.text}}</p>
                            </div>
                            <div class="content_action">
                              <button class="btn_primary" (click)="copyText(article.text)">
                                <span nz-icon nzType="snippets" nzTheme="outline"></span>
                                <span>Add to Post</span>
                              </button>
                              <div class="dtl_count">
                                <span>{{article.text.length}}</span>
                                <!--<span>
                                  <img src="../../../assets/images/Facebook.png" alt="">
                                  <span>63,206</span>
                                </span>-->
                              </div>
                            </div>
                          </ng-container>

                        </div>

                        <div class="writing_list_in" *ngIf="articles.length>0">
                          <button class="btn_primary" (click)="regenerateArticles()">Try Again</button>
                        </div>

                      </div>
                    </div>


                  </ng-container>
                </nz-drawer>
              </div>

              <div class="post_input_action_right">
                <!--Auto Hashtag-->
                <ng-container>
                  <!-- *ngIf="!sharedService.isMobile" -->
                  <!-- <button nz-popover [nzPopoverContent]="contentTemplate"
                          [(nzPopoverVisible)]="autoHashtagVisible"
                          nzPopoverTrigger="click"
                    class="btn_auto_hash" [class.disabled_while_loading]="isLoading"
                    [disabled]='selectedAccounts.length===0'>
                    <svg id="Layer_2" enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m30 12.5c.82813 0 1.5-.67139 1.5-1.5s-.67187-1.5-1.5-1.5h-5.74921l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80859-.14404-1.59473.39697-1.74023 1.21289l-1.38636 7.76367h-8.08551l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80664-.14404-1.59375.39697-1.74023 1.21289l-1.38636 7.76367h-8.07068c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h7.53497l-1.25 7h-6.28497c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h5.74921l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.08551l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.07068c.82813 0 1.5-.67139 1.5-1.5s-.67188-1.5-1.5-1.5h-7.53497l1.25-7zm-10.58221 7h-8.08557l1.25-7h8.08557z"/></g></svg>
                    <span>Auto Hashtags web</span>
                  </button> -->
                  <button type="button" class="btn btn_auto_hash" [class.disabled_while_loading]="isLoading"
                    [disabled]="selectedAccounts?.length===0 || postForm.get('content')?.value?.length==0"
                    (click)="autoHashTagDrawer()">
                    <!-- <svg id="Layer_2" enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512"
                        xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="m30 12.5c.82813 0 1.5-.67139 1.5-1.5s-.67187-1.5-1.5-1.5h-5.74921l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80859-.14404-1.59473.39697-1.74023 1.21289l-1.38636 7.76367h-8.08551l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80664-.14404-1.59375.39697-1.74023 1.21289l-1.38636 7.76367h-8.07068c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h7.53497l-1.25 7h-6.28497c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h5.74921l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.08551l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.07068c.82813 0 1.5-.67139 1.5-1.5s-.67188-1.5-1.5-1.5h-7.53497l1.25-7zm-10.58221 7h-8.08557l1.25-7h8.08557z"/>
                      </g>
                    </svg> -->

                    <!-- <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.73781 3.73236C3.62058 3.85051 3.55469 4.01031 3.55469 4.17677C3.55469 4.34339 3.62057 4.50304 3.73781 4.62134C3.85717 4.73903 4.01803 4.80492 4.18571 4.80492C4.35324 4.80492 4.5141 4.73903 4.63346 4.62134C4.78765 4.46048 4.84551 4.23025 4.78568 4.01562C4.72585 3.80099 4.55742 3.63377 4.34232 3.57559C4.12722 3.51742 3.89745 3.57711 3.73781 3.73236Z" fill="#494ADF"/>
                      <path d="M18.4436 9.43897L9.56123 0.556606C9.2024 0.195657 8.71301 -0.00502284 8.20407 9.55623e-05H1.89332C1.39121 9.55623e-05 0.90969 0.199578 0.554659 0.554628C0.199628 0.909679 0.000126805 1.39118 0.000126805 1.89328V8.22459C-0.0057803 8.73368 0.194913 9.22338 0.556637 9.58175L9.43901 18.444C9.7942 18.7999 10.2763 19 10.7792 19C11.282 19 11.7642 18.7999 12.1193 18.444L18.4503 12.113C18.8017 11.7563 18.998 11.2754 18.9968 10.7748C18.9954 10.2742 18.7967 9.79435 18.4434 9.43944L18.4436 9.43897ZM17.5546 11.2236L11.2237 17.5545V17.5546C11.1004 17.6616 10.9426 17.7203 10.7793 17.7203C10.616 17.7203 10.4582 17.6616 10.3347 17.5546L1.45236 8.67187C1.33361 8.55327 1.26757 8.39196 1.26923 8.22413V1.89282C1.26923 1.72544 1.33572 1.56489 1.45402 1.44658C1.57232 1.32829 1.73287 1.26179 1.90025 1.26179H8.23117C8.399 1.26089 8.56001 1.32677 8.67907 1.44492L17.5549 10.3277C17.6742 10.4461 17.7413 10.6073 17.7413 10.7754C17.7413 10.9437 17.6742 11.1049 17.5549 11.2233L17.5546 11.2236Z" fill="#494ADF"/>
                      <path d="M13.2585 8.29822C13.1403 8.18098 12.9805 8.11524 12.8141 8.11524C12.6476 8.11524 12.4878 8.18098 12.3695 8.29822L11.7995 8.86818L10.6662 7.73488L11.2362 7.16492L11.2363 7.16507C11.3724 7.00224 11.4175 6.78216 11.3566 6.57889C11.2956 6.37577 11.1367 6.21674 10.9336 6.15585C10.7303 6.09481 10.5101 6.1401 10.3474 6.27612L9.77743 6.84608L9.20747 6.27612C9.09387 6.14025 8.92831 6.05831 8.75139 6.05043C8.57432 6.04255 8.40225 6.10935 8.27701 6.23462C8.15174 6.35988 8.08494 6.53195 8.09282 6.709C8.1007 6.88591 8.18264 7.05147 8.31851 7.16508L8.88847 7.73504L7.75517 8.86834L7.18521 8.29838V8.29823C7.02253 8.16221 6.8023 8.11707 6.59919 8.17796C6.39592 8.239 6.23703 8.39789 6.17599 8.60116C6.1151 8.80428 6.16024 9.02451 6.29626 9.18718L6.86622 9.75714L6.29626 10.3271V10.3273C6.17902 10.4454 6.11328 10.6052 6.11328 10.7717C6.11328 10.9381 6.17902 11.0979 6.29626 11.2161C6.41531 11.3318 6.57481 11.3965 6.74082 11.3965C6.90684 11.3965 7.06617 11.3318 7.18523 11.2161L7.7552 10.6461L8.8885 11.7794L8.31853 12.3494C8.2013 12.4675 8.1354 12.6273 8.1354 12.7938C8.1354 12.9604 8.20129 13.1201 8.31853 13.2384C8.43758 13.3539 8.59693 13.4186 8.76294 13.4186C8.92895 13.4186 9.08844 13.3539 9.2075 13.2384L9.77747 12.6684L10.3474 13.2384C10.4665 13.3539 10.626 13.4186 10.7918 13.4186C10.9579 13.4186 11.1173 13.3539 11.2364 13.2384C11.3536 13.1201 11.4194 12.9604 11.4194 12.7938C11.4194 12.6273 11.3536 12.4675 11.2364 12.3494L10.6664 11.7794L11.7997 10.6461L12.3697 11.2161H12.3696C12.4886 11.3318 12.6481 11.3965 12.8141 11.3965C12.9801 11.3965 13.1395 11.3318 13.2585 11.2161C13.3758 11.0979 13.4417 10.9381 13.4417 10.7717C13.4417 10.6052 13.3758 10.4454 13.2585 10.3273L12.6886 9.7573L13.2585 9.18733V9.18718C13.3758 9.06903 13.4417 8.90923 13.4417 8.74277C13.4417 8.5763 13.3758 8.41651 13.2585 8.2982L13.2585 8.29822ZM9.77063 10.8971L8.63733 9.76379L9.77063 8.63049L10.9039 9.76379L9.77063 10.8971Z" fill="#494ADF"/>
                    </svg> -->


                    <svg height="512pt" viewBox="-31 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m30 316c-16.570312 0-30 13.429688-30 30 0 16.566406 13.429688 30 30 30h59l-14 106c0 16.566406 13.429688 30 30 30s30-13.433594 30-30l14-106h120l-14 106c0 16.566406 13.429688 30 30 30s30-13.433594 30-30l14-106h61c16.570312 0 30-13.433594 30-30 0-16.570312-13.429688-30-30-30h-53l16-120h67c16.570312 0 30-13.433594 30-30 0-16.570312-13.429688-30-30-30h-59l14-106c0-16.570312-13.429688-30-30-30s-30 13.429688-30 30l-14 106h-120l14-106c0-16.570312-13.429688-30-30-30s-30 13.429688-30 30l-14 106h-61c-16.570312 0-30 13.429688-30 30 0 16.566406 13.429688 30 30 30h53l-16 120zm143-120h120l-16 120h-120zm0 0" />
                    </svg>

                    <span>Auto Hashtags</span>
                  </button>
                  <ng-template #contentTemplate>
                    <div class="cus_has_tag_main">
                      <div class="cus_has_tag_in">
                        <label>Max number of hashtags</label>
                        <nz-input-number [(ngModel)]="hashtagLimit" [nzMin]="1" [nzMax]="20" [nzStep]="1"
                          nzPlaceHolder="Max Hashtags" [ngModelOptions]="{standalone: true}"></nz-input-number>
                      </div>
                      <div class="cus_has_tag_in">
                        <label>Hashtag position</label>
                        <nz-radio-group [(ngModel)]="hashtagPosition" [ngModelOptions]="{standalone: true}">
                          <label nz-radio-button nzValue="auto"><span>Anywhere</span></label>
                          <label nz-radio-button nzValue="end"><span>at the end</span></label>
                        </nz-radio-group>
                      </div>
                      <div class="cus_has_btn">
                        <button class="btn_secondary" nz-button nzType="primary" (click)="closeModal()"
                          [disabled]="isAutoHashtagLoading">
                          Cancel
                        </button>
                        <button class="btn_primary" nz-button nzType="primary" (click)="getAutoHashtagText()"
                          [disabled]="isAutoHashtagLoading">
                          Apply
                          <ng-container *ngIf="isAutoHashtagLoading">
                            <span nz-icon nzType="loading"></span>
                          </ng-container>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>

                <!-- <ng-container *ngIf="sharedService.isMobile">
                  <button type="button" class="btn_auto_hash" [class.disabled_while_loading]="isLoading"
                          [disabled]='selectedAccounts.length===0' (click)="autoHashtagModal()">
                    <svg id="Layer_2" enable-background="new 0 0 32 32" height="512" viewBox="0 0 32 32" width="512"
                        xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="m30 12.5c.82813 0 1.5-.67139 1.5-1.5s-.67187-1.5-1.5-1.5h-5.74921l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80859-.14404-1.59473.39697-1.74023 1.21289l-1.38636 7.76367h-8.08551l1.29218-7.23633c.14551-.81543-.39746-1.59473-1.21289-1.74023-.80664-.14404-1.59375.39697-1.74023 1.21289l-1.38636 7.76367h-8.07068c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h7.53497l-1.25 7h-6.28497c-.82813 0-1.5.67139-1.5 1.5s.67188 1.5 1.5 1.5h5.74921l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.08551l-1.29218 7.23633c-.14551.81543.39746 1.59473 1.21289 1.74023.08887.01611.17773.02393.26563.02393.71289 0 1.34473-.51025 1.47461-1.23682l1.38635-7.76367h8.07068c.82813 0 1.5-.67139 1.5-1.5s-.67188-1.5-1.5-1.5h-7.53497l1.25-7zm-10.58221 7h-8.08557l1.25-7h8.08557z"/>
                      </g>
                    </svg>
                    <span>Auto Hashtags</span>
                  </button>
                  <nz-modal [(nzVisible)]="isVisibleHashtagModal" [nzFooter]=null [nzClassName]="'isVisibleHashtagModal'">
                    <ng-container *nzModalContent>
                      <div class="cus_has_tag_main">
                        <div class="cus_has_tag_in">
                          <label>Max number of hashtags</label>
                          <nz-input-number [(ngModel)]="hashtagLimit" [nzMin]="1" [nzMax]="20" [nzStep]="1"
                                          nzPlaceHolder="Max Hashtags"
                                          [ngModelOptions]="{standalone: true}"></nz-input-number>
                        </div>
                        <div class="cus_has_tag_in">
                          <label>Hashtag position</label>
                          <nz-radio-group [(ngModel)]="hashtagPosition" [ngModelOptions]="{standalone: true}">
                            <label nz-radio-button nzValue="auto"><span>Anywhere</span></label>
                            <label nz-radio-button nzValue="end"><span>at the end</span></label>
                          </nz-radio-group>
                        </div>
                        <div class="cus_has_btn">
                          <button class="btn_secondary" nz-button nzType="primary" (click)="closeModal()">
                            Cancel
                          </button>
                          <button class="btn_primary" nz-button nzType="primary" (click)="getAutoHashtagText()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </nz-modal>
                </ng-container> -->
                <!--Auto Hashtag-->

                <button type="button" class="btn btn_hash" [class.disabled_while_loading]="isLoading"
                  (click)="hashTagDrawer()" [disabled]='selectedAccounts.length===0'>
                  <!-- <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6485 0H0V19H14V3.33556L10.6485 0ZM12.9394 17.9444H1.06061V1.05556H10.0121V4.17996H12.9394L12.9394 17.9444ZM12.2394 3.12444H11.0727V1.87893L12.2394 3.12444Z" fill="#494ADF"/>
                    <path d="M2.54297 3.80078H8.90661V5.06745H2.54297V3.80078Z" fill="#494ADF"/>
                    <path d="M2.54297 6.33398H11.543V7.60065H2.54297V6.33398Z" fill="#494ADF"/>
                    <path d="M8.91831 14.5879C8.91775 14.4446 8.8602 14.3072 8.75833 14.2059C8.65647 14.1045 8.51846 14.0472 8.37433 14.0466L7.67678 14.0466L7.67678 12.6662L8.37433 12.6662L8.37433 12.6664C8.5572 12.65 8.71949 12.5435 8.80661 12.3826C8.89355 12.2218 8.89364 12.0281 8.80661 11.8674C8.71958 11.7064 8.5571 11.5998 8.37433 11.5836L7.67678 11.5836L7.67678 10.8894C7.69041 10.7375 7.63925 10.5867 7.5358 10.4742C7.43227 10.3616 7.28611 10.2974 7.13281 10.2975C6.97951 10.2975 6.83334 10.3616 6.72982 10.4742C6.62638 10.5867 6.57522 10.7375 6.58884 10.8894L6.58884 11.5836L5.20186 11.5836L5.20186 10.8894L5.20195 10.8893C5.18564 10.7074 5.0785 10.5458 4.91695 10.4592C4.75521 10.3725 4.56076 10.3726 4.39902 10.4592C4.23747 10.5458 4.13033 10.7074 4.11402 10.8893L4.11402 11.5835L3.41648 11.5835L3.41638 11.5836C3.27235 11.5842 3.13434 11.6415 3.03247 11.7428C2.93061 11.8442 2.87305 11.9816 2.8725 12.1249C2.87454 12.2679 2.93256 12.4044 3.03415 12.5055C3.13573 12.6066 3.27281 12.6643 3.41648 12.6663L4.11402 12.6663L4.11402 14.0467L3.41648 14.0467C3.27244 14.0472 3.13434 14.1044 3.03248 14.2058C2.93052 14.3073 2.87315 14.4446 2.8725 14.5881C2.87463 14.7309 2.93256 14.8674 3.03414 14.9685C3.13573 15.0696 3.2729 15.1273 3.41648 15.1295L4.11402 15.1295L4.11402 15.8237C4.11615 15.9666 4.17418 16.1031 4.27567 16.2041C4.37725 16.3052 4.51443 16.3629 4.658 16.3651C4.80213 16.3644 4.94005 16.3072 5.04201 16.2058C5.14387 16.1044 5.20143 15.967 5.20198 15.8237L5.20198 15.1295L6.58896 15.1295L6.58896 15.8237L6.58887 15.8236C6.59091 15.9666 6.64893 16.1031 6.75052 16.2042C6.85211 16.3053 6.98918 16.3629 7.13285 16.365C7.27688 16.3644 7.41499 16.3072 7.51685 16.2059C7.61871 16.1045 7.67618 15.967 7.67673 15.8237L7.67673 15.1295L8.37428 15.1295L8.37437 15.1294C8.51841 15.1288 8.65651 15.0716 8.75837 14.9702C8.86024 14.8689 8.9177 14.7314 8.91835 14.588L8.91831 14.5879ZM5.19373 14.0466L5.19373 12.6662L6.58071 12.6662L6.58071 14.0466L5.19373 14.0466Z" fill="#494ADF"/>
                  </svg> -->
                  <svg fill="#494ADF" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                      <path d="M405.3,192H106.7C83.2,192,64,172.8,64,149.3v-42.7C64,83.2,83.2,64,106.7,64h298.7c23.5,0,42.7,19.2,42.7,42.7v42.7
                        C448,172.8,428.8,192,405.3,192z M106.7,106.7v42.7h298.7v-42.7H106.7z" />
                    </g>
                    <g>
                      <path d="M192,448h-85.3C83.2,448,64,428.8,64,405.3v-128c0-23.5,19.2-42.7,42.7-42.7H192c23.5,0,42.7,19.2,42.7,42.7v128
                        C234.7,428.8,215.5,448,192,448z M106.7,277.3v128H192v-128H106.7z" />
                    </g>
                    <path
                      d="M257.7,362.1c-7.5,0-13.6,6.1-13.6,13.6c0,7.5,6.1,13.6,13.6,13.6h26.7l-6.3,48c0,7.5,6.1,13.6,13.6,13.6
                      s13.6-6.1,13.6-13.6l6.3-48H366l-6.3,48c0,7.5,6.1,13.6,13.6,13.6s13.6-6.1,13.6-13.6l6.3-48h27.6c7.5,0,13.6-6.1,13.6-13.6
                      c0-7.5-6.1-13.6-13.6-13.6h-24l7.2-54.4h30.3c7.5,0,13.6-6.1,13.6-13.6c0-7.5-6.1-13.6-13.6-13.6h-26.7l6.3-48
                      c0-7.5-6.1-13.6-13.6-13.6s-13.6,6.1-13.6,13.6l-6.3,48h-54.4l6.3-48c0-7.5-6.1-13.6-13.6-13.6s-13.6,6.1-13.6,13.6l-6.3,48h-27.6
                      c-7.5,0-13.6,6.1-13.6,13.6c0,7.5,6.1,13.6,13.6,13.6h24l-7.2,54.4H257.7z M322.5,307.8h54.4l-7.2,54.4h-54.4L322.5,307.8z" />
                  </svg>
                  <span>Hashtags & Templates</span>
                </button>
              </div>
            </div>
          </div>
          <!-- <textarea class="content" nz-input placeholder="Start to Write" formControlName="content"
            (keyup)="setValidation()" #inputContent [readOnly]="isFeedPost"
            [class.disabled_while_loading]="isLoading"></textarea> -->


          <div class="social_max_legth_msg">
            <div *ngIf="
                  postForm.get('content').value !== null &&
                  postForm.get('content').value.trimStart().length > 280 &&
                  allAccountProvider.includes(this.sharedService.twitterProvider)
                " class="warning-color">
              <img src="assets/images/Twitter.png" alt="Twitter Logo" class="icon" />
              Max limit of Twitter is 280 characters, and exceeding that limit
              will convert the rest of the text into Twitter thread.
            </div>

            <!--(postForm.get('content').touched || postForm.get('content').dirty) &&-->
            <div *ngIf="postForm.get('content').hasError('linkedinMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/LinkedIn.png" alt="LinkedIn Logo" class="icon" />
              {{ postForm.get("content").getError("linkedinMaxlength") }}
            </div>
            <div *ngIf="postForm.get('content').hasError('instagramMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/Instagram.png" alt="Instagram Logo" class="icon" />
              {{ postForm.get("content").getError("instagramMaxlength") }}
            </div>
            <div *ngIf="postForm.get('content').hasError('facebookMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/Facebook.png" alt="Facebook Logo" class="icon" />
              {{ postForm.get("content").getError("facebookMaxlength") }}
            </div>
            <div *ngIf="postForm.get('content').hasError('youtubeMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/YouTube.png" alt="YouTube Logo" class="icon" />
              {{ postForm.get("content").getError("youtubeMaxlength") }}
            </div>
            <div *ngIf="postForm.get('content').hasError('pinterestMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/Pinterest.png" alt="Pinterest Logo" class="icon" />
              {{ postForm.get("content").getError("pinterestMaxlength") }}
            </div>
            <div *ngIf="postForm.get('content').hasError('tiktokMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/tiktok.svg" class="icon" />
              {{ postForm.get("content").getError("tiktokMaxlength") }}
            </div>
            <div *ngIf="postForm.get('content').hasError('threadsMaxlength') &&
              postForm.get('content').invalid" class="error-color">
              <img src="assets/images/threads.png" class="icon" />
              {{ postForm.get("content").getError("threadsMaxlength") }}
            </div>
          </div>
          <div>
            <div class="des_action" *ngIf="!isFeedPost">

              <button type="button" href="javascript:;" (click)="openUploadModel()" nz-tooltip nzTooltipTitle="Gallery"
                [disabled]="currentUploading.length>0 || isLoading">
                <img src="./assets/images/des_action_icon_1.svg" alt="">
              </button>

              <button type="button" href="javascript:;" [disabled]="currentUploading.length>0 || isLoading"
                class="upload_media" nz-tooltip
                [nzTooltipTitle]="selectedAccounts.length===0 ? 'Upload Media : Please select an account before creating any post' : 'Upload'">
                <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzLimit]="10"
                  [nzAccept]="supportedMediaFiles" [nzDisabled]="selectedAccounts.length===0">
                  <img src="./assets/images/upload_media.svg" style="height:24px;" alt="">
                  <!-- Start dragging message -->
                  <div class="dragging_msg_main">
                    <div class="dragging_msg_in">
                      <div class="icon_sec">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="512" height="512" x="0" y="0" viewBox="0 0 612 612"
                          style="enable-background:new 0 0 512 512" xml:space="preserve">
                          <g>
                            <path
                              d="M494.7 255c-17.85-86.7-94.35-153-188.7-153-73.95 0-137.7 40.8-168.3 102C58.65 214.2 0 277.95 0 357c0 84.15 68.85 153 153 153h331.5c71.4 0 127.5-56.1 127.5-127.5 0-66.3-53.55-122.4-117.3-127.5zM357 331.5v102H255v-102h-76.5L306 204l127.5 127.5H357z"
                              fill="#4046cd" opacity="1" data-original="#4046cd"></path>
                          </g>
                        </svg>
                      </div>
                      <div class="content_sec">
                        <span class="msg_error">Please select an account before creating any post</span>
                        <span class="msg_success">Drop file to upload</span>
                      </div>
                    </div>
                  </div>
                  <!-- End dragging message -->
                </nz-upload>
              </button>

              <button type="button" href="javascript:;" nz-tooltip
                [nzTooltipTitle]="selectedAccounts.length===0 ? 'Upload GIF : Please select an account before creating any post' : 'Upload GIF'"
                [disabled]="currentUploading.length>0 || isLoading">

                <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzMultiple]="false"
                  nzAccept=".gif" [nzDisabled]="selectedAccounts.length===0">
                  <img src="./assets/images/des_action_icon_2.svg" alt="">
                </nz-upload>
              </button>

              <button *ngIf="allAccountProvider.includes(sharedService.facebookProvider) ||
                        allAccountProvider.includes(sharedService.instagramProvider)"
                      type="button" nz-tooltip nzTooltipTitle="Location" (click)="showLocationModal()">
                <img src="../../../assets/images/location.svg" alt="" style="height: 24px;"
                     [class.active]="this.selectedLocation!=null">
              </button>

              <button type="button" href="javascript:;" nz-dropdown [nzDropdownMenu]="openEmojis" nzTrigger="click"
                nz-tooltip nzTooltipTitle="Emojis" [disabled]="isLoading">
                <img src="../../../assets/images/emoji_icon.svg" alt="">
              </button>

              <nz-dropdown-menu #openEmojis="nzDropdownMenu">
                <div class="btn_publish_dropdown_menu">
                  <emoji-mart [showSingleCategory]="true" [perLine]="8" (emojiClick)="addEmoji($event)"
                    [showPreview]="false"></emoji-mart>
                </div>
              </nz-dropdown-menu>

              <!-- <a href="javascript:;"><img src="./assets/images/des_action_icon_5.svg" alt=""></a> -->
              <!--nz-tooltip nzTooltipTitle="Tag detected! Use platform-specific tagging to add tags"-->

              <!--<ng-container *ngIf="betaUsers.includes(userId)">-->
              <ng-container>
                <button *ngIf="allAccountProvider.includes(sharedService.facebookProvider) ||
                          allAccountProvider.includes(sharedService.instagramProvider) ||
                          allAccountProvider.includes(sharedService.threadsProvider) ||
                          allAccountProvider.includes(sharedService.tiktokProvider) ||
                          allAccountProvider.includes(sharedService.twitterProvider)"
                        type="button"  (click)="platFormSpecificDrawer()">
                    <img src="../../../assets/images/mention_icon.svg" alt="">
                </button>
              </ng-container>

              <button type="button" [disabled]="selectedAccounts.length===0 || isLoading" (click)="isImageGenerateModal=true">
                <img src="../../../assets/images/chipset.svg" style="height: 24px;" alt="">
              </button>

            </div>
            <div class="error-color text-14 upload_img_error">
              <span>{{ error }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--Instagram Story-->
      <!--<div class="post_upload_warning"
        *ngIf="allAccountProvider.includes(sharedService.instagramProvider) &&
          images.length>1 &&
          postForm.value.instagram_type ==='story_post'">
            <nz-alert
              nzType="warning"
              nzMessage="The first media file will be published"
              nzDescription="The Instagram direct publishing workflow only supports one media file per story."
              nzShowIcon
          ></nz-alert>
      </div>-->

      <!--Tiktok Story-->
      <div class="post_upload_warning" *ngIf="allAccountProvider.includes(sharedService.tiktokProvider) &&
            images.length>1">
        <nz-alert nzType="warning" nzMessage="TikTok only supports the publishing of video"
          nzDescription="The TikTok direct publishing workflow only supports one video per post." nzShowIcon></nz-alert>
      </div>

      <div class="app_upload_div">

        <!-- <app-upload [imageFile]="uploadFiles" [maxLength]="maxLength" [isForUpdate]="false" [initLoadGallery]="false"
          [allAccountProvider]="allAccountProvider" [images]="images" [mediaData]="mediaData"
          [galleryMediaData]="galleryMediaData" [type]="type" (deleteChange)="deleteChange($event)"
          [youtubeThumbnails]="youtubeThumbnails" *ngIf="images.length > 0" [isFeedPost]="isFeedPost"
          (editImageChanges)="editImageChanges($event)">
        </app-upload> -->


        <app-gallery-preview *ngIf="images.length > 0" [imageFile]="uploadFiles" [maxLength]="maxLength"
          [isForUpdate]="false" [initLoadGallery]="false" [allAccountProvider]="allAccountProvider" [images]="images"
          [mediaData]="mediaData" [galleryMediaData]="galleryMediaData" [type]="type"
          (deleteChange)="deleteChange($event)" [youtubeThumbnails]="youtubeThumbnails" [isFeedPost]="isFeedPost"
          [instagramType]="postForm.value.instagram_type" [instaReelThumbnails]="instaReelThumbnails"
          (editImageChanges)="editImageChanges($event)" [page]="'create_post'" [isPosting]="isLoading"
                             (imagesChanged)="updateImagesIndex($event)"
                             [multi_images]="multiImages">
        </app-gallery-preview>

        <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false"
          *ngFor="let uploading of currentUploading">
          <ng-container>
            <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
              *ngIf="uploading.loading"></nz-progress>
            <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
          </ng-container>
        </nz-upload>

      </div>

      <!-- instagram reel start -->
      <!-- *ngIf="allAccountProvider.includes(sharedService.instagramProvider) && postForm.value.instagram_type == 'reel_post'" -->
      <div class="provider_collapse" *ngIf="allAccountProvider.includes(sharedService.instagramProvider)">
        <div class=" provider_collapse_in">
          <nz-collapse class="instagram_form">
            <nz-collapse-panel nzHeader="Instagram" [nzActive]="true">

              <!--*ngIf="allAccountProvider.includes(sharedService.instagramProvider) && type === 'video' "-->
              <div class="form_input_wrp">
                <div class="title_sec">
                  <h5 class="text-24">Instagram Post Type
                    <!-- <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i> -->
                  </h5>
                </div>
                <div class="form_input_in">
                  <nz-form-item class="description_input">
                    <nz-input-group>
                      <nz-form-control>
                        <nz-radio-group formControlName="instagram_type" (ngModelChange)="isCommentDisplay()">
                          <label nz-radio nzValue="feed_post" [class.disabled_while_loading]="isLoading">
                            Standard Post
                          </label>
                          <label nz-radio nzValue="reel_post"
                            [class.disabled_while_loading]="isLoading || (type==='image' || type==='gif')">
                            Reel Post
                          </label>
                          <label nz-radio nzValue="story_post" [class.disabled_while_loading]="isLoading">
                            Story
                          </label>
                        </nz-radio-group>
                      </nz-form-control>
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>

              <div class="form_input_wrp"
                *ngIf="allAccountProvider.includes(sharedService.instagramProvider) && postForm.value.instagram_type ==='reel_post' && (type === 'video' || type==='multi')">
                <div class="title_sec">
                  <h5 class="text-24">In Instagram do you want to post it as a standard post also?
                    <!-- <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i> -->
                  </h5>
                </div>
                <div class="form_input_in">
                  <nz-form-item class="inst_stad_post">
                    <nz-input-group>
                      <nz-form-control>
                        <label nz-checkbox nzDisabled formControlName="is_instagram_reels_post"
                          [class.disabled_while_loading]="isLoading">
                          <span>Reels</span>
                        </label>
                      </nz-form-control>
                      <nz-form-control>
                        <label nz-checkbox formControlName="is_instagram_standard_post"
                          [class.disabled_while_loading]="isLoading">
                          <span>Standard post</span>
                        </label>
                      </nz-form-control>
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>

              <!--Collaborators-->
              <div class="form_input_wrp"
                *ngIf="allAccountProvider.includes(sharedService.instagramProvider) && postForm.value.instagram_type !=='story_post' ">
                <div class="title_sec">
                  <h5 class="text-24">Collaborators</h5>
                  <span>You can use Instagram Collabs to co-author posts with other accounts.
                    The creator of the original post can invite another account as a collaborator.
                    If the account accepts the invite, their username will be added to the post and the post will be shared with their followers.
                    Simply add the co-authors IG username below (without the @ symbol) and they will be notified through Instagram.</span>
                </div>
                <div class="form_input_in">

                  <!--<input class="inst_stad_post" nz-input placeholder="Enter Instagram username"
                    formControlName="instagram_collaborators"
                         type="text" />-->
                  <div class="insta_collaborators post_to_input">
                    <div class="insta_message">
                      <span>Limited to 3 accounts</span>
                    </div>
                    <nz-select class="inst_stad_post" nzPlaceHolder="Enter Instagram username"
                      formControlName="instagram_collaborators" [nzMaxMultipleCount]="3" nzMode="tags" nzAllowClear>
                    </nz-select>
                  </div>

                </div>
              </div>

            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <!-- instagram reel end -->

      <!-- Start Tiktok collapse -->
      <ng-container *ngIf="postForm && creatorInfo.length && allAccountProvider.includes(sharedService.tiktokProvider)"
        formArrayName="tiktok_creator_info">
        <div class="provider_collapse" *ngFor="let account of creatorInfo; let i=index">
          <div class="provider_collapse_in" *ngIf="account" [formGroupName]="i">
            <nz-collapse class="instagram_form">
              <nz-collapse-panel [nzHeader]="'TikTok Options (' + getAccountNameLevel(account.value.social_id) +')'"
                [nzActive]="true">
                <div class="tiktok_input">
                  <nz-form-item>
                    <label>Who can view this video</label>
                    <nz-select formControlName="privacy_level" nzSize="large">
                      <nz-option *ngFor="let level of getAccountPrivacyLevel(account.value.social_id)"
                        [nzLabel]="getFormatedLable(level)" [nzValue]="level">
                      </nz-option>
                    </nz-select>
                    <div *ngIf="postForm.get('tiktok_creator_info')?.get(i.toString())?.get('privacy_level')?.invalid &&
                        (postForm.get('tiktok_creator_info')?.get(i.toString())?.get('privacy_level')?.touched)"
                      class="error-color tiktok_error">
                      TikTok Privacy level is required.
                    </div>
                  </nz-form-item>
                  <label nz-checkbox formControlName="allow_comment" nzValue="comments">Allow comments</label>
                  <label nz-checkbox formControlName="allow_duet" nzValue="duets">Allow duets</label>
                  <label nz-checkbox formControlName="allow_stitch" nzValue="stitches">Allow stitches</label>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </ng-container>
      <!-- End Tiktok collapse-->
      <div class="form_input_wrp"
        *ngIf="isCommentVisible && isCommentVisibleForYT && isCommentVisibleForLinkedIn && isCommentVisibleForInstagram && !isFeedPost">
        <div class="title_sec">
          <h5 class="text-24">First Comment <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add first comment on facebook, instagram, linkedin and youtube."></i>
          </h5>
          <div class="content-length">
            <div class="count_area">
              <ng-container
                *ngIf="allAccountProvider.length > 0 && (allAccountProvider.length > 1 || (allAccountProvider.length === 1 && !allAccountProvider.includes(sharedService.twitterProvider)))">
                <ul>
                  <li><span>{{ postForm.get("first_comment")?.value === null ? 0 :
                      postForm.get("first_comment")?.value.trimStart().length }}</span><span>/</span></li>
                  <ng-container *ngFor="let accProvider of allAccountProvider">
                    <li *ngIf="accProvider === sharedService.facebookProvider">
                      <div class="content-length">
                        <img src="assets/images/Facebook.png" alt="Facebook Logo" class="icon" />
                        <span
                          [class.error-color]="postForm.get('first_comment')?.value !== null &&
                         this.sharedService.getCommentLength(sharedService.facebookProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.facebookProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.linkedinProvider && (type==='image' || type==='gif') ">
                      <div class="content-length">
                        <img src="assets/images/LinkedIn.png" alt="LinkedIn Logo" class="icon" />
                        <span
                          [class.error-color]="
                                postForm.get('first_comment')?.value !== null && this.sharedService.getCommentLength(sharedService.linkedinProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.linkedinProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.instagramProvider">
                      <div class="content-length">
                        <img src="assets/images/Instagram.png" alt="Instagram Logo" class="icon" />
                        <span
                          [class.error-color]="postForm.get('first_comment')?.value !== null && this.sharedService.getCommentLength(sharedService.instagramProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.instagramProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.youtubeProvider && postForm.value.audience === 'false'">
                      <div class="content-length">
                        <img src="assets/images/YouTube.png" alt="YouTube Logo" class="icon" />
                        <span
                          [class.error-color]="postForm.get('first_comment')?.value !== null && this.sharedService.getCommentLength(sharedService.youtubeProvider) - postForm.get('first_comment')?.value.trimStart().length <=0">
                          {{ this.sharedService.getCommentLength(sharedService.youtubeProvider) -
                          postForm.get("first_comment")?.value?.trimStart().length }}
                        </span>
                      </div>
                    </li>

                  </ng-container>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="form_input_in">
          <nz-form-item class="description_input">
            <nz-input-group>
              <nz-form-control>
                <textarea class="margin-top-1" nz-input placeholder="Write Your First Comment"
                  formControlName="first_comment" [class.disabled_while_loading]="isLoading"></textarea>
                <div class="social_max_legth_msg">
                  <div *ngIf="postForm.get('first_comment')?.hasError('instagramCommentMaxlength')" class="error-color">
                    <img src="assets/images/Instagram.png" alt="Instagram Logo" class="icon" />
                    {{ postForm.get("first_comment")?.getError("instagramCommentMaxlength") }}
                  </div>
                  <div *ngIf="postForm.get('first_comment')?.hasError('facebookCommentMaxlength')" class="error-color">
                    <img src="assets/images/Facebook.png" alt="Facebook Logo" class="icon" />
                    {{ postForm.get("first_comment")?.getError("facebookCommentMaxlength") }}
                  </div>
                  <div *ngIf="type==='image' && postForm.get('first_comment')?.hasError('linkedinCommentMaxlength')"
                    class="error-color">
                    <img src="assets/images/LinkedIn.png" alt="LinkedIn Logo" class="icon" />
                    {{ postForm.get("first_comment")?.getError("linkedinCommentMaxlength") }}
                  </div>
                  <div
                    *ngIf="postForm.get('first_comment')?.hasError('youtubeCommentMaxlength') && postForm.value.audience === 'false'"
                    class="error-color">
                    <img src="assets/images/YouTube.png" alt="YouTube Logo" class="icon" />
                    {{ postForm.get("first_comment")?.getError("youtubeCommentMaxlength") }}
                  </div>
                </div>
              </nz-form-control>
            </nz-input-group>
          </nz-form-item>
        </div>
      </div>
      <!---->
      <div class="form_input_wrp" *ngIf="allAccountProvider.includes(sharedService.youtubeProvider) ||
      allAccountProvider.includes(sharedService.pinterestProvider)">
        <div class="title_sec">
          <h5 class="text-24">Title <i nz-icon nzType="info-circle" nzTheme="fill"
              nzTooltipOverlayClassName="information" nz-tooltip nzTooltipPlacement="right" nzTooltipTrigger="hover"
              nzTooltipTitle="You can only add title on YouTube and Pinterest."></i></h5>
          <div class="content-length">
            <div class="count_area" *ngIf="allAccountProvider.includes(sharedService.youtubeProvider) ||
            allAccountProvider.includes(sharedService.pinterestProvider)">
              <ul>
                <li><span>{{ postForm.get("title")?.value === null ? 0 : postForm.get("title")?.value.trimStart().length
                    }}</span><span>/</span></li>
                <ng-container *ngIf="allAccountProvider.length > 0">

                  <ng-container *ngFor="let accProvider of allAccountProvider">
                    <li *ngIf="accProvider === sharedService.youtubeProvider">
                      <div class="content-length">
                        <img src="assets/images/YouTube.png" alt="YouTube Logo" class="icon" />
                        <span *ngIf="postForm.get('title')?.value !== null" [class.error-color]="
                          postForm.get('title')?.value !== null &&
                          this.sharedService.getTitleLength(sharedService.youtubeProvider) -
                            postForm.get('title')?.value.trimStart().length <= 0">

                          {{ this.sharedService.getTitleLength(sharedService.youtubeProvider) -
                          postForm.get("title")?.value.trimStart().length
                          }}
                        </span>
                      </div>
                    </li>
                    <li *ngIf="accProvider === sharedService.pinterestProvider">
                      <div class="content-length">
                        <img src="assets/images/Pinterest.png" alt="Pinterest Logo" class="icon" />
                        <span *ngIf="postForm.get('title')?.value !== null" [class.error-color]="
                          postForm.get('title')?.value !== null &&
                          this.sharedService.getTitleLength(sharedService.pinterestProvider) -
                            postForm.get('title')?.value.trimStart().length <=0">
                          {{ this.sharedService.getTitleLength(sharedService.pinterestProvider) -
                          postForm.get("title")?.value.trimStart().length }}
                        </span>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="form_input_in">
          <nz-form-item class="title_input">
            <nz-form-control [nzSm]="24" [nzXs]="24">

              <input nz-input formControlName="title" placeholder="Enter Title" class="form-control-input"
                [class.disabled_while_loading]="isLoading" (keydown.enter)="prevent($event)" />

            </nz-form-control>
            <div>
              <ng-container>
                <div *ngIf="postForm.get('title').hasError('youtubeTitleMaxlength')  " class="error-color">
                  <img src="assets/images/YouTube.png" alt="YouTube Logo" class="icon" />
                  {{ postForm.get("title")?.getError("youtubeTitleMaxlength") }}
                </div>
              </ng-container>
              <ng-container>
                <div *ngIf="postForm.get('title').hasError('pinterestTitleMaxlength') " class="error-color">
                  <img src="assets/images/Pinterest.png" alt="Pinterest Logo" class="icon" />
                  {{ postForm.get("title")?.getError("pinterestTitleMaxlength") }}
                </div>
              </ng-container>
            </div>
          </nz-form-item>
        </div>
      </div>

      <div class="provider_collapse">
        <div class="provider_collapse_in" *ngIf="allAccountProvider.includes(sharedService.youtubeProvider)">
          <nz-collapse class="youtube_form">
            <nz-collapse-panel nzHeader="YouTube" [nzActive]="true">

              <nz-form-item class="youtube_form_item">
                <nz-form-label>YouTube Video Tags</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-select nzMode="tags" [nzTokenSeparators]="[',']" [(ngModel)]="listOfVideoTags"
                    nzPlaceHolder="Enter comma-separated values" formControlName="youtube_video_tag"
                    [nzDisabled]="isLoading">
                  </nz-select>
                </nz-form-control>
              </nz-form-item>


              <nz-form-item>
                <nz-form-label>Privacy Settings</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-select formControlName="privacy" [nzDisabled]="isLoading">
                    <nz-option nzValue="public" nzLabel="Public"></nz-option>
                    <nz-option nzValue="private" nzLabel="Private"></nz-option>
                    <nz-option nzValue="unlisted" nzLabel="Unlisted"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Category</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-select formControlName="category" [nzDisabled]="isLoading">
                    <nz-option *ngFor="let cat of youtubeCategories" [nzValue]="cat.id" [nzLabel]="cat.title">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Audience</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-radio-group formControlName="audience" (ngModelChange)="isCommentDisplay()"
                    [nzDisabled]="isLoading">
                    <label nz-radio nzValue="true">Yes, it's made for kids</label>
                    <label nz-radio nzValue="false">No, it's not made for kids</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>

            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <div class="provider_collapse_in" *ngIf="allAccountProvider.includes(sharedService.pinterestProvider)">
          <nz-collapse>
            <nz-collapse-panel nzHeader="Pinterest" [nzActive]="true">

              <nz-form-item>
                <nz-form-label>Link</nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <input nz-input formControlName="pinterest_link" placeholder="Enter Link" class="input_title"
                    [class.disabled_while_loading]="isLoading" (keydown.enter)="prevent($event)" />

                </nz-form-control>
              </nz-form-item>
              <nz-form-item *ngFor="let account of pinterestAccounts">
                <nz-form-label>Board ({{account.name}})</nz-form-label>
                <nz-select [nzDropdownRender]="renderTemplate" nzPlaceHolder="Select Board"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="account.board_id"
                  (ngModelChange)="changeBoard($event,account.social_id)" [nzDisabled]="isLoading">

                  <nz-option *ngFor="let board of pinterestBoards[account.social_id]" [nzValue]="board.id"
                    [nzLabel]="board.name"></nz-option>
                </nz-select>
                <ng-template #renderTemplate>
                  <nz-divider></nz-divider>
                  <div class="container select_input_container">
                    <input type="text" nz-input #inputElement placeholder="Enter Board Name"
                      [disabled]="isBoardCreating" />
                    <button type="button" nz-button nzType="primary"
                      (click)="createPinterestBoard(inputElement, account.social_id)"
                      [disabled]="isLoading || isBoardCreating">
                      Create Board
                      <ng-container *ngIf="isBoardCreating">
                        <span nz-icon nzType="loading"></span>
                      </ng-container>
                    </button>
                  </div>

                </ng-template>
              </nz-form-item>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <div class="btn_publish intro_post_actions" joyrideStep="post_schedule" stepPosition="top"
        [stepContent]="submitPostContent" *ngIf="!id && !isFeedPost && assigneeUsers.length==0">

        <ng-template #submitPostContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/calendar_icon.png" alt="">
            </div>
            <div class="detail_sec">
              <p>Schedule your post! Choose a date and time in the future, or publish it right away for instant
                visibility. You can also choose to repeat the post on a recurring basis, or save a draft for later.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>


        <button type="button" class="btn_publish_in "
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading" (click)="openScheduleModel()">
          Schedule
          <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>

        <button type="button" class="btn_publish_in" (click)="openRepeatPopup()"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Repeat Post
          <ng-container *ngIf="isLoading && loadingPostType==='repeat'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>

        <!--<button type="button" class="btn_publish_in"
                [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading"
                (click)="submit({submit_type:'queue'})">
          Queue Now
          <ng-container *ngIf="isLoading && loadingPostType==='queue'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>-->

        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'draft'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Save as Draft
          <ng-container *ngIf="isLoading && loadingPostType==='draft'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>


        <button type="button" class="btn_publish_in btn_post" (click)="submit({submit_type:'post'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Post Now
          <ng-container *ngIf="isLoading && loadingPostType==='post'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>

      </div>

      <!--Feed-->
      <div class="btn_publish" *ngIf="isFeedPost">

        <ng-template #customContent>
          <div class="step_content_wrapper">
            <div class="icon_sec">
              <img src="../../../assets/images/summery_img_3.png" alt="">
            </div>
            <div class="detail_sec">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <!-- <button (click)="finishTour()">End tour</button> -->
            </div>
          </div>
        </ng-template>


        <button *ngIf="userRole !== 'Moderator'" type="button" class="btn_publish_in"
          [disabled]="isPublishBtnDisabled() || postForm.invalid ||  this.selectedAccounts.length === 0 || isLoading"
          (click)="openScheduleModel()">
          Schedule
          <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
            <!-- && loadingPostType=='schedule' -->
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button *ngIf="currentPageRole !== 'Moderator'" type="button" class="btn_publish_in"
          (click)="submit({submit_type:'post'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isMultipleRoleSelected ||  this.selectedAccounts.length === 0 || isLoading">
          Post Now
          <ng-container *ngIf="isLoading && loadingPostType==='post'">
            <!-- && loadingPostType=='post' -->
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>

      <!--Custom-->
      <!--<div class="btn_publish" *ngIf="!id && userRole === 'Custom' && !isFeedPost">-->
      <!-- <div class="btn_publish" *ngIf="!id && !isFeedPost && assigneeUsers.length>0">
        <button type="button" class="btn_publish_in" (click)="openRepeatPopup()"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Repeat Post
          <ng-container *ngIf="isLoading && loadingPostType==='repeat'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading" (click)="openScheduleModel()">
          Schedule
          <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'draft'})"
          [disabled]="isPublishBtnDisabled() || isLoading || postForm.invalid">
          Save as Draft
          <ng-container *ngIf="isLoading && loadingPostType==='draft'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'post'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Post Now
          <ng-container *ngIf="isLoading && loadingPostType==='post'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div> -->

      <!--Custom-->
      <!--<div class="btn_publish" *ngIf="!id && userRole === 'Custom' && !isFeedPost">-->
      <div class="btn_publish" *ngIf="!id && !isFeedPost && assigneeUsers.length>0">
        <button type="button" class="btn_publish_in" (click)="openRepeatPopup()"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Repeat Post
          <ng-container *ngIf="isLoading && loadingPostType==='repeat'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading" (click)="openScheduleModel()">
          Schedule
          <ng-container *ngIf="isLoading && loadingPostType==='schedule'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <!--<button type="button" class="btn_publish_in"
                [disabled]="isPublishBtnDisabled() || isLoading || postForm.invalid"
                (click)="submit({submit_type:'queue'})">
          Queue Now
          <ng-container *ngIf="isLoading && loadingPostType==='queue'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>-->
        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'draft'})"
          [disabled]="isPublishBtnDisabled() || isLoading || postForm.invalid">
          Save as Draft
          <ng-container *ngIf="isLoading && loadingPostType==='draft'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
        <button type="button" class="btn_publish_in" (click)="submit({submit_type:'post'})"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading">
          Post Now
          <ng-container *ngIf="isLoading && loadingPostType==='post'">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>

      <div class="btn_publish" *ngIf="id">
        <button type="button" class="btn_publish_in" (click)="back()" [disabled]="isLoading">
          Back
        </button>
        <button type="button" class="btn_publish_in"
          [disabled]="isPublishBtnDisabled() || postForm.invalid || isLoading"
          (click)="postStatus===this.sharedService.schedule ? openScheduleModel() : submit()">
          Update
          <ng-container *ngIf="isLoading">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </button>
      </div>

    </form>
  </div>

  <div class="create_content_right">

    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 8 }" *ngIf="this.selectedAccounts.length === 0"></nz-skeleton>

    <app-preview-post *ngIf="this.selectedAccounts?.length > 0" [userData]="this.selectedAccounts"
                      [title]="postForm.value.title"
                      [profilePic]="profilePic()" [name]="username()"
                      [content]="postForm.value.content" [firstComment]="postForm.value.first_comment"

                      [images]="images"
                      [mediaData]="mediaData"
                      [galleryMediaData]="galleryMediaData"

                      [type]="type"
                      [audience]="postForm.value.audience" [edit]="false"
                      [delete]="false"
                      [id]="null"
                      [validationErrors]="validationErrors"
                      [postErrors]="postFailed"
                      [from]="''"
                      [YTThumbnail]="youtubeThumbnails?.length>0 ? youtubeThumbnails[0] : ''"
                      [instaReelThumbnail]="instaReelThumbnails?.length>0 ? instaReelThumbnails[0] : ''"
                      [instagramType]="postForm.value.instagram_type"
                      [isCommentVisible]="isCommentVisibleForLinkedIn"
                      [boardName]="boardName"
                      [selectedLocation]="selectedLocation"
                      [facebookMentions]="selectedFacebookMentions"
                      [instagramMentions]="selectedInstagramMentions"
                      [twitterMentions]="selectedTwitterMentions"
                      [threadsMentions]="selectedThreadsMentions"
                      [tiktokMentions]="selectedTiktokMentions"
                      [multi_images]="multiImages"
                    >
    </app-preview-post>


    <!--Assign details-->
    <div class="cp_details_main" *ngIf="sharedService.canAccessTeam()
          && (isAssigneeUsersLoading ||  assigneeUsers.length>0) && !user.is_super_admin">

      <ng-container *ngIf="isAssigneeUsersLoading">
        <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
      </ng-container>

      <ng-container *ngIf="!isAssigneeUsersLoading && assigneeUsers.length>0">
        <div class="title_wrp">
          <h5>Details</h5>
        </div>
        <div class="cp_details_body">
          <div class="cp_details_in">
            <label>Assignee</label>
            <div class="cp_details_form drop_down_arrow">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzPlaceHolder="Please select Assignee" nzDropdownClassName="cp_assignee"
                    [nzCustomTemplate]="cp_assignee" nzMode="multiple" [(ngModel)]="selectedAssigneeUsers">
                    <nz-option nzCustomContent *ngFor="let user of assigneeUsers" [nzValue]="user">
                      <div class="cp_team_main">
                        <div class="img_sec">
                          <app-image-preview [imageUrl]="user?.profile_pic" [class]="'profile-image'"
                            [isVideo]="false"></app-image-preview>
                        </div>
                        <div class="content_sec">
                          <h5>{{user.name}}</h5>
                        </div>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
                <ng-template #cp_assignee let-selected>
                  <div class="ant-select-selection-item-content">
                    <div class="cp_team_main">
                      <div class="img_sec">
                        <app-image-preview [imageUrl]="selected.nzValue?.profile_pic" [class]="'profile-image'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </nz-form-item>
            </div>
          </div>
          <div class="cp_details_in">
            <label>Created by</label>
            <div class="cp_details_form">
              <div class="crted_by">
                <div class="img_wrp">
                  <app-image-preview [imageUrl]="user.profile_pic" [class]="'profile-image'"
                    [isVideo]="false"></app-image-preview>
                </div>
                <div class="dtl_wrp">
                  <h5>{{user.name}}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="cp_details_in">
            <label>Priority</label>
            <div class="cp_details_form">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzPlaceHolder="Please select priority" nzDropdownClassName="cp_priority"
                    [nzCustomTemplate]="cp_priority" [(ngModel)]="selectedPriority">
                    <nz-option nzCustomContent *ngFor="let priority of sharedService.priorities; let i=index"
                      [nzValue]="priority">
                      <div class="cp_prior_wrp">
                        <img [src]="'../../../assets/images/'+priority.image" [alt]="priority.name">
                        <span [class]="priority.class">{{priority.name}}</span>
                      </div>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
                <ng-template #cp_priority let-selected>
                  <div class="ant-select-selection-item-content">
                    <div class="cp_prior_wrp">
                      <img [src]="'../../../assets/images/'+selected.nzValue.image" [alt]="selected.nzValue.name">
                      <span [class]="selected.nzValue.class">{{selected.nzValue.name}}</span>
                    </div>
                  </div>
                </ng-template>
              </nz-form-item>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!--</form>-->
</div>

<nz-drawer nzWrapClassName="hash_temp_drawer" [nzClosable]="true" [nzVisible]="hashTag" nzPlacement="right"
  nzTitle="Templates & Hashtags" (nzOnClose)="close()">
  <ng-container *nzDrawerContent>
    <nz-tabset class="tabset_default">
      <nz-tab nzTitle="Suggestions" (nzClick)="suggestion()">
        <!-- Search Start -->
        <!-- <div class="search_temp">
          <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
              fill="#676DF4" />
          </svg>
          <nz-input-group nzSearch [nzPrefix]="suffixIconSearch" nzSize="large">
            <input type="text" nz-input placeholder="Search Hashtag" #searchInput
              (keyup)="onKeyUp(searchInput.value, 1)" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
                fill="#676DF4" />
            </svg>
          </ng-template>
        </div> -->
        <!-- Search end -->

        <!-- Search static start -->
        <ng-container *ngIf="connectTwitter">
          <div class="search_temp">
            <!-- <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
              fill="#676DF4" />
          </svg>
          <input nz-input /> -->


            <nz-input-group nzSearch [nzPrefix]="suffixIconSearch" nzSize="large">
              <input type="text" nz-input placeholder="Search Hashtag" #searchInput
                (keyup)="onKeyUp(searchInput.value, 1)" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <svg class="anticon" width="19" height="18" viewBox="0 0 19 18" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.6429 3.84948H15.1733L15.5884 1.49414C15.7117 0.794832 15.212 0.134021 14.473 0.017336C13.7296 -0.0979452 13.0345 0.373846 12.911 1.07191L12.4221 3.84948H8.38884L8.804 1.49414C8.92724 0.794832 8.4276 0.134021 7.6886 0.017336C6.94514 -0.0979452 6.25002 0.373846 6.12661 1.07191L5.63638 3.84948H2.71429C1.96489 3.84948 1.35714 4.42409 1.35714 5.13261C1.35714 5.87843 1.96489 6.41574 2.71429 6.41574H5.18386L4.27924 11.5483H1.35714C0.607746 11.5483 0 12.1229 0 12.8314C0 13.5399 0.607746 14.0784 1.35714 14.0784H3.82672L3.41156 16.4338C3.28831 17.1331 3.78796 17.7939 4.52696 17.9106C4.60156 17.996 4.6779 18 4.75 18C5.40143 18 5.97652 17.5553 6.08721 16.9282L6.57663 14.1506H10.6107L10.1956 16.506C10.0723 17.2053 10.572 17.8661 11.311 17.9828C11.3873 17.996 11.4636 18 11.5357 18C12.1871 18 12.7622 17.5553 12.8729 16.9282L13.3623 14.1506H16.2857C17.0351 14.1506 17.6429 13.576 17.6429 12.9036C17.6429 12.195 17.0351 11.6204 16.2857 11.6204H13.8161L14.7208 6.48792H17.6429C18.3923 6.48792 19 5.91372 19 5.24088C19 4.45897 18.3935 3.84948 17.6429 3.84948ZM11.065 11.5483H7.0317L7.93504 6.41574H11.9692L11.065 11.5483Z"
                  fill="#676DF4" />
              </svg>
            </ng-template>
          </div>


          <!-- Search static end -->

          <div class="has_temp_input_wrp">
            <div class="has_temp_input_main">
              <ng-container *ngIf="searchText !== ''">

                <div class="has_temp_input">
                  <ng-container *ngIf="connectTwitter && isLoadingHas">

                    <cdk-virtual-scroll-viewport itemSize="73" class="demo-infinite-container"
                      (scrolledIndexChange)="nextBatch($event)">

                      <ng-container *ngFor="let hashTag of hashList">
                        <ng-container *ngIf="searchResult && searchInput.value !== ''">
                          <label nz-checkbox [(ngModel)]="hashTag.checked" (ngModelChange)="SingleCheckedHash()">
                            <div class="has_temp_input_in">
                              <h5>#{{hashTag.name}}</h5>
                            </div>
                          </label>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="isSpinning">
                        <div class="msg_list_skeleton">
                          <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
                            *ngFor="let hashtag of staticConversationHash">
                          </nz-skeleton>
                        </div>
                      </ng-container>
                    </cdk-virtual-scroll-viewport>
                  </ng-container>
                  <ng-container *ngIf="emptyData">
                    <div class="demo-infinite-container">
                      <span style="align-items: center;">
                        <app-empty [message]="'No Hashtag found !'"></app-empty>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="searchText === ''">
                <div class="search_hastag">
                  <img src="../../../assets/images/search-svg.svg" alt="" srcset="">
                  <span>Start Search for <strong>#Hashtags</strong> !</span>
                </div>
              </ng-container>
            </div>
            <!-- && searchText != '' -->
            <ng-container *ngIf="connectTwitter && searchResult.length > 0">
              <div class="has_temp_btn_group">
                <div class="filter_count_header">
                  <strong>Selected ({{selectedHash}})</strong>
                  <a href="javascript:;" [class.disabled_while_loading]="selectedHashList.length == 0"
                    (click)="deselectHash()">Deselect All</a>
                </div>
                <div class="btn_group">
                  <button type="button" class="btn" nz-dropdown [nzDropdownMenu]="menu"
                    [nzDisabled]="selectedHashList.length == 0">Save <span nz-icon nzType="down"></span></button>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                      <li nz-menu-item (click)="showModal()">
                        As new template
                      </li>
                      <li nz-menu-item (click)="existingTemp()" [nzDisabled]="allTemplate.length==0">
                        To existing template
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                  <!-- <button type="button" nz-button nzType="primary" (click)="addToPostDirect()" [nzLoading]="selectedHashList.length == 0" >Add to Post</button> -->
                  <button nz-button nzType="primary" class="btn btn_apply" (click)="addToPostDirect()"
                    [disabled]="selectedHashList.length === 0">Add to Post
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!connectTwitter">
          <!-- <div class="hashtag_limitation">
        <div class="hashtag_limitation_left">
          <i nz-icon nzType="warning" nzTheme="fill"></i>
        </div>
        <div class="hashtag_limitation_right">
          <h4> Hashtag Limitation</h4>
          <div class="lmted_msg">
            <i nz-icon nzType="twitter" nzTheme="outline"></i>
            <p>To get the suggestion for hashtag , you need to connect twitter account.</p>
          </div>
        </div>
      </div> -->
          <div class="twitr_con_msg">
            <!--<div class="icon_sec">
              <span nz-icon nzType="twitter" nzTheme="outline"></span>
              <strong>#</strong>
            </div>-->
            <div class="search_hastag" style="margin: 0;border:none;">
              <img src="../../../assets/images/search-svg.svg" alt="" srcset="">
              <div class="con_sec">
                <p>To get the suggestion for <strong>hashtag</strong> , you need to connect
                  <strong>X (Formerly Twitter)</strong> account.
                </p>
              </div>
            </div>
          </div>
        </ng-container>

      </nz-tab>
      <nz-tab nzTitle="Templates" (nzClick)="getAllTemplate()">

        <div class="has_temp_input_wrp">
          <div class="new_temp_area">
            <a href="javascript:;" (click)="showModal()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17 9C17 9.55228 16.5523 10 16 10H10V16C10 16.5523 9.55228 17 9 17V17C8.44772 17 8 16.5523 8 16V10H2C1.44772 10 1 9.55228 1 9V9C1 8.44772 1.44772 8 2 8H8V2C8 1.44772 8.44772 1 9 1V1C9.55228 1 10 1.44772 10 2V8H16C16.5523 8 17 8.44772 17 9V9Z"
                  fill="#423FD1" />
                <path
                  d="M10 10V9.5H9.5V10H10ZM8 10H8.5V9.5H8V10ZM8 8V8.5H8.5V8H8ZM10 8H9.5V8.5H10V8ZM16 9.5H10V10.5H16V9.5ZM9.5 10V16H10.5V10H9.5ZM8.5 16V10H7.5V16H8.5ZM8 9.5H2V10.5H8V9.5ZM2 8.5H8V7.5H2V8.5ZM8.5 8V2H7.5V8H8.5ZM9.5 2V8H10.5V2H9.5ZM10 8.5H16V7.5H10V8.5ZM9 1.5C9.27614 1.5 9.5 1.72386 9.5 2H10.5C10.5 1.17157 9.82843 0.5 9 0.5V1.5ZM8.5 2C8.5 1.72386 8.72386 1.5 9 1.5V0.5C8.17157 0.5 7.5 1.17157 7.5 2H8.5ZM1.5 9C1.5 8.72386 1.72386 8.5 2 8.5V7.5C1.17157 7.5 0.5 8.17157 0.5 9H1.5ZM9 16.5C8.72386 16.5 8.5 16.2761 8.5 16H7.5C7.5 16.8284 8.17157 17.5 9 17.5V16.5ZM9.5 16C9.5 16.2761 9.27614 16.5 9 16.5V17.5C9.82843 17.5 10.5 16.8284 10.5 16H9.5ZM2 9.5C1.72386 9.5 1.5 9.27614 1.5 9H0.5C0.5 9.82843 1.17157 10.5 2 10.5V9.5ZM16 8.5C16.2761 8.5 16.5 8.72386 16.5 9H17.5C17.5 8.17157 16.8284 7.5 16 7.5V8.5ZM16 10.5C16.8284 10.5 17.5 9.82843 17.5 9H16.5C16.5 9.27614 16.2761 9.5 16 9.5V10.5Z"
                  fill="#423FD1" />
              </svg>
              <span>Create New Template</span>
            </a>
          </div>
          <div class="has_temp_input_main temp_container">
            <ng-container *ngIf="isHashLoading">
              <div class="msg_list_skeleton">
                <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
                  *ngFor="let hashtag of staticConversationHash">
                </nz-skeleton>
              </div>
            </ng-container>
            <ng-container *ngIf="allTemplate.length>0 && !isHashLoading">
              <perfect-scrollbar style="max-height: 100%;">
                <div class="has_temp_input btn_edit_main">
                  <nz-radio-group [(ngModel)]="templateContent" nzName="radiogroup">
                    <ng-container *ngFor="let template of allTemplate">
                      <label nz-radio nzValue="{{template.content}}">
                        <div class="has_temp_input_in">
                          <h5>{{template.name}}</h5>
                          <span>{{template.content}}</span>
                          <div class="has_temp_action">
                            <a href="javascript:;" (click)="editModal(template)">
                              <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.1163 4.13023L17.8698 6.88238L15.1163 4.13023ZM16.887 1.70569L9.44173 9.15092C9.05703 9.53507 8.79467 10.0245 8.68771 10.5575L8 14L11.4425 13.311C11.9755 13.2044 12.4643 12.9431 12.8491 12.5583L20.2943 5.11305C20.518 4.88932 20.6955 4.62371 20.8166 4.33139C20.9377 4.03907 21 3.72577 21 3.40937C21 3.09296 20.9377 2.77966 20.8166 2.48734C20.6955 2.19502 20.518 1.92942 20.2943 1.70569C20.0706 1.48196 19.805 1.30448 19.5127 1.1834C19.2203 1.06232 18.907 1 18.5906 1C18.2742 1 17.9609 1.06232 17.6686 1.1834C17.3763 1.30448 17.1107 1.48196 16.887 1.70569V1.70569Z"
                                  stroke="#232C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                  d="M17.7652 15.4119V18.7649C17.7652 19.3578 17.5296 19.9263 17.1104 20.3455C16.6912 20.7648 16.1227 21.0003 15.5298 21.0003H3.23535C2.6425 21.0003 2.07393 20.7648 1.65472 20.3455C1.23551 19.9263 1 19.3578 1 18.7649V6.47046C1 5.87761 1.23551 5.30904 1.65472 4.88983C2.07393 4.47062 2.6425 4.23511 3.23535 4.23511H6.58838"
                                  stroke="#232C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                            <a href="javascript:;" (click)="deleteTemplate(template._id)">
                              <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                                  stroke="#232C89" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </label>
                    </ng-container>
                  </nz-radio-group>
                </div>
              </perfect-scrollbar>
            </ng-container>
            <ng-container *ngIf="allTemplate.length == 0 && !isHashLoading">
              <div class="no_templte_empty">
                <app-empty [message]="'No Template Found !'"></app-empty>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="allTemplate.length > 0 && !isHashLoading">

            <div class="has_temp_btn_group">
              <!-- <div class="filter_count_header">
              <strong>Selected (2)</strong>
              <a href="javascript:;">Deselect All</a>
            </div> -->
              <div class="btn_group">
                <button type="button" class="btn btn_apply" (click)="AddToPost(templateContent)"
                  [disabled]="templateContent === ''">Add to Post
                </button>
              </div>
            </div>

          </ng-container>
        </div>

      </nz-tab>
    </nz-tabset>
  </ng-container>
</nz-drawer>

<nz-drawer nzWrapClassName="auto_hash_tag_drawer" [nzClosable]="false" [nzVisible]="autoHashTag" nzPlacement="right">
  <!-- (nzOnClose)="closeAutoHashtag()" -->
  <ng-container *nzDrawerContent>
    <div class="auto_hash_new_hdr">
      <div class="auto_hash_new_hdr_top">
        <h5>Auto Hashtags</h5>
        <a href="javascript:;" (click)="closeAutoHashtag()">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
              fill="#202449"></path>
          </svg>
        </a>
      </div>
      <div class="auto_hash_new_hdr_btm">
        <img src="../../../assets/images/auto_hash_tag.png" alt="">
        <div class="dtl_wrp_in">
          <h5>Unleash the power of A.I.</h5>
          <p>These numbers show you how many people are looking at each hashtag per hour! Make your selection now!</p>
        </div>
      </div>
    </div>

    <!-- <div class="hastag_list_header" *ngIf="hashtagsFromText.length > 0">
      <span>Exposure Per Hour</span>
    </div> -->
    <div class="hastag_list">

      <ng-container *ngIf="isAutoHashtagLoading">
        <div class="msg_list_skeleton">
          <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }" *ngFor="let hashtag of staticConversationHash">
          </nz-skeleton>
        </div>
      </ng-container>

      <ng-container *ngIf="!isAutoHashtagLoading">

        <ng-container *ngIf="hashtagsFromText.length === 0">
          <div class="search_hastag" style="margin: 0;">
            <img src="../../../assets/images/search-svg.svg" alt="" srcset="">
            <span>No <strong>#Hashtags</strong> Suggestions Found!</span>
          </div>
        </ng-container>

        <ng-container *ngFor="let autoHash of hashtagsFromText;let i=index">
          <label nz-checkbox [(ngModel)]="autoHash.is_checked" (ngModelChange)="updateAutoHashtag()">
            <div class="has_temp_input_in">
              <h5>#{{autoHash.hashtag}}</h5>
              <!-- <div class="tag_count">
                <span>{{autoHash.exposure}} exposures per hour</span>
              </div> -->
              <div class="tag_progress">
                <nz-progress nzStrokeColor="#423fd1" [nzShowInfo]="false"
                  [nzPercent]="i==0?100:sharedService.getPercentage(autoHash.exposure_number,hashtagsFromText[0]?.['exposure_number'])"></nz-progress>
              </div>
            </div>
          </label>
        </ng-container>

      </ng-container>

    </div>
    <div class="has_temp_btn_group" *ngIf="hashtagsFromText.length > 0">
      <div class="filter_count_header">
        <strong>Selected ({{selectedAutoHashtags.length}})</strong>

        <ng-container *ngIf="selectedAutoHashtags.length==0">
          <a href="javascript:;" (click)="selectAllAutoHashtags()">Select All</a>
        </ng-container>
        <ng-container *ngIf="selectedAutoHashtags.length!=0">
          <a href="javascript:;" (click)="deselectAllAutoHashtags()">Deselect All</a>
        </ng-container>

      </div>
      <div class="btn_group">
        <button nz-button nzType="primary" class="btn btn_apply" (click)="addAutoHashtagToPost()"
          [disabled]="selectedAutoHashtags.length==0">Add to Post
        </button>
      </div>
    </div>
  </ng-container>
</nz-drawer>



<nz-drawer nzWrapClassName="auto_hash_tag_drawer platform_specific_drawer" [nzClosable]="false" [nzVisible]="isPlatFormSpecific" nzPlacement="right">
  <ng-container *nzDrawerContent>
    <div class="auto_hash_new_hdr">
      <div class="auto_hash_new_hdr_top">
        <h5>Platform-Specific Tagging</h5>
        <span class="tag_beta">Beta</span>

        <a href="javascript:;" (click)="closePlatFormSpecificDrawer()">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z" fill="#202449"></path>
          </svg>
        </a>
      </div>
      <div class="auto_hash_new_hdr_btm">
        <img src="../../../assets/images/choose-user-icon.png" alt="">
        <div class="dtl_wrp_in">
          <h5>Choose users for each platform to tag them.</h5>
          <p>Tags are platform-specific and will only be included in posts for that platform.</p>
        </div>
      </div>
    </div>
    <div class="platform_body">
      <div class="platform_body_in">

        <!--Facebook-->
        <div class="platform_form_group" *ngIf="allAccountProvider.includes(this.sharedService.facebookProvider)">
          <div class="label">
            <img src="../../../assets/images/Facebook.png" alt="">
            <span>{{ this.sharedService.facebookProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Search and select Facebook Page</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false" nzPlaceHolder="@"
                       nzMode="multiple" [nzCustomTemplate]="multipleTemplate"
                       nzServerSearch (nzOnSearch)="facebookPageSearch($event)" [(ngModel)]="facebookSearchPagesList">
+
              <nz-option *ngIf="isFacebookPageSearchLoading" nzDisabled nzCustomContent>
                <span nz-icon nzType="loading" class="loading-icon"></span>
                Searching...
              </nz-option>

              <ng-container *ngFor="let page of facebookPages">
                <nz-option nzCustomContent *ngIf="!isLocationLoading" [nzLabel]="page.name" [nzValue]="page">
                  <div class="account_info">
                    <span class="selected_icon"></span>
                    <div class="account_img">
                      <img [src]="page.image" alt="">
                    </div>
                    <div class="account_content">
                      <span>
                        {{ page.name }}
                        <img *ngIf="page.is_verified"
                             src="../../../assets/images/blue_verified.svg" alt="" style="width:15px" />
                      </span>

                    </div>
                  </div>
                </nz-option>
              </ng-container>
            </nz-select>

            <ng-template #multipleTemplate let-selected>
              <div class="ant-select-selection-item-content">
                <div class="selected_platform">
                  <div class="account_img">
                    <img [src]="selected.nzValue?.image" alt="">
                  </div>
                  <div class="account_content">
                    <span>{{ selected.nzValue?.name }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <!--Instagram-->
        <div class="platform_form_group" *ngIf="allAccountProvider.includes(this.sharedService.instagramProvider)">
          <div class="label">
            <img src="../../../assets/images/Instagram.png" alt="">
            <span>{{ this.sharedService.instagramProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid Instagram username (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="instagramSearchPagesList"
                       (ngModelChange)="validateInstagramSearchInput($event)">
            </nz-select>
          </div>
        </div>

        <!--Twitter-->
        <div class="platform_form_group" *ngIf="allAccountProvider.includes(this.sharedService.twitterProvider)">
          <div class="label">
            <img src="../../../assets/images/Twitter.png" alt="">
            <span>X(Twitter)</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid X(Twitter) username (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="twitterSearchPagesList"
                       (ngModelChange)="validateTwitterSearchInput($event)">
            </nz-select>
          </div>
        </div>

        <!--Threads-->
        <div class="platform_form_group" *ngIf="allAccountProvider.includes(this.sharedService.threadsProvider)">
          <div class="label">
            <img src="../../../assets/images/threads.png" alt="">
            <span>{{ this.sharedService.threadsProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid Threads usern`zame (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="threadsSearchPagesList"
                       (ngModelChange)="validateThreadsSearchInput($event)">
            </nz-select>
          </div>
        </div>

        <!--Tiktok-->
        <div class="platform_form_group" *ngIf="allAccountProvider.includes(this.sharedService.tiktokProvider)">
          <div class="label">
            <img src="../../../assets/images/tiktok.svg" alt="">
            <span>{{ this.sharedService.tiktokProvider | titlecase}}</span>
          </div>
          <div class="select_platform">
            <p>Enter a valid TikTok username (e.g. @example)</p>
            <nz-select nzDropdownClassName="platform_dropdown" [nzAllowClear]="false"
                       nzPlaceHolder="@" nzMode="tags"
                       [(ngModel)]="tiktokSearchPagesList"
                       (ngModelChange)="validateTiktokSearchInput($event)">
            </nz-select>
          </div>
        </div>


      </div>

      <div class="has_temp_btn_group">
        <div class="btn_group">
          <button type="button" class="btn btn_apply" (click)="addTags()">Add Tags</button>
        </div>
      </div>

    </div>
  </ng-container>
</nz-drawer>

<nz-modal nzClassName="create_temp_modal" [(nzVisible)]="isVisible" nzTitle="{{modalTitle}}" [nzFooter]="null"
  (nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <div class="create_temp_form">
      <form nz-form [formGroup]="templateForm">
        <div>
          <label>Template Name</label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Template name is required">
              <input nz-input formControlName="template_name" placeholder="Enter Template Name" required />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div>
          <label>Description</label>
          <nz-form-item>
            <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Template Description is required">
              <textarea class="temp_des" nz-input formControlName="template_description" placeholder="Start to Write"
                required></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </div>
    <div class="btn_group">
      <button nz-button nztype="primary" class="ant-btn ant-btn-primary" (click)="SaveTemplate()"
        [disabled]="isHashLoading">
        <span> {{buttonName}} </span>
        <ng-container *ngIf="isHashLoading ">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </ng-container>
</nz-modal>

<nz-modal nzClassName="create_temp_modal" [(nzVisible)]="isexistingTemp" nzTitle="To existing template"
  [nzFooter]="null" (nzOnCancel)="existingTempCancel()">
  <ng-container *nzModalContent>
    <div class="create_temp_form">
      <label>Select template</label>
      <nz-select nzShowSearch [nzPlaceHolder]="'Select template'" [(ngModel)]="selectedValue"
        (ngModelChange)="updateTemplate($event)">

        <nz-option *ngFor="let item of allTemplate" [nzLabel]='item.name' [nzValue]='item.name'></nz-option>
        <!-- <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option> -->

      </nz-select>
    </div>
    <div class="btn_group">
      <button nz-button nztype="primary" class="ant-btn ant-btn-primary" [disabled]="isHashLoading"
        (click)="updateTemplateData()">
        <span> Save
          <ng-container *ngIf="isHashLoading ">
            <span nz-icon nzType="loading"></span>
          </ng-container>
        </span>
      </button>

    </div>
  </ng-container>
</nz-modal>

<app-payment-modal *ngIf="isPaymentModalVisible" [isVisible]="isPaymentModalVisible" [modalTitle]="'Buy Addon'"
  [plan]="selectedAddon" [submitText]="'Pay now'" (onCancel)="cancelPayment()"
  (onSubmit)="submitHandelForAddon($event)"></app-payment-modal>


<!--Post Modal-->
<nz-modal [(nzVisible)]="isPostingModal" [nzTitle]="titlePosts" nzClassName="posting_modal" [nzFooter]="null">
  <ng-template #titlePosts let-visible="visible">
    <ng-container *ngIf="validationErrors.length == 0">
      {{ isLoading === true ? 'Posting in Progress 🚀' : countKeys(platformPostStatus)===0
      ? "Oops, Something went wrong" : 'Posting Complete! 🎉'
      }}
    </ng-container>
    <ng-container *ngIf="validationErrors.length > 0">
      Oops, Something went wrong
    </ng-container>
  </ng-template>

  <ng-container *nzModalContent>
    <div class="posting_warning">

      <img src="../../../assets/images/info_icon.svg" alt="">

      <!-- Top warning-->
      <ng-container *ngIf="validationErrors.length == 0">
        <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
          <!--post-->
          <ng-container *ngIf="loadingPostType==='post'">
            <p *ngIf="isLoading">Your Post is being shared to the selected platforms. Here is the current status:</p>
            <p *ngIf="!isLoading">Your post has been successfully shared across all platforms:</p>
          </ng-container>

          <!--schedule-->
          <ng-container *ngIf="loadingPostType==='schedule' || loadingPostType==='repeat'">
            <p *ngIf="isLoading">Your Post is being scheduling to the selected platforms. Here's the current status:</p>
            <p *ngIf="!isLoading">Your post has been successfully scheduled across all platforms:</p>
          </ng-container>

          <!--draft-->
          <ng-container *ngIf="loadingPostType==='draft'">
            <p *ngIf="isLoading">Your Post is being drafting to the selected platforms. Here's the current status:</p>
            <p *ngIf="!isLoading">Your post has been successfully drafted across all platforms:</p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
          Something went wrong
        </ng-container>
      </ng-container>
      <ng-container *ngIf="validationErrors.length > 0">
        <p>Unable to post, Please resolve the errors first</p>
      </ng-container>
    </div>


    <div class="posting_body">
      <div class="platform_progress">

        <ng-container *ngIf="validationErrors.length===0">
          <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
            <div *ngFor="let provider of allAccountProvider; let i = index" class="platform_progress_in"
              [class.post_completed]="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.success"
              [class.post_failed]="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.failed"
              [class.post_warning]="!isLoading && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.success
               && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.failed">
              <div class="icon_sec">
                <img *ngIf="provider === sharedService.facebookProvider" src="../../../assets/images/Facebook.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.twitterProvider" src="../../../assets/images/Twitter.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.instagramProvider" src="../../../assets/images/Instagram.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.youtubeProvider" src="../../../assets/images/YouTube.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.pinterestProvider" src="../../../assets/images/Pinterest.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.linkedinProvider" src="../../../assets/images/LinkedIn.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.tiktokProvider" src="../../../assets/images/post_tiktok.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.threadsProvider" src="../../../assets/images/threads.png"
                  [alt]="provider">
              </div>
              <div class="detail_sec">
                <div class="title_sec">
                  <a class="btn_toggle" href="javascript:;" (click)="isToggle(i)"
                    [class.disabled_while_loading]="isLoading" [class.selected]="isSelectedAccountToggle === i">
                    <!-- <span nz-icon nzType="down" nzTheme="outline"></span> -->
                    <img src="../../../assets/images/down_arrow.svg" alt="">
                  </a>
                  <h5>{{ sharedService.platformName(provider) | titlecase }}</h5>

                  <div class="post_status_main">

                    <ng-container
                      *ngIf="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.success">
                      <span>Completed</span>
                      <img src="../../../assets/images/status_complete.svg" alt="">
                    </ng-container>

                    <ng-container
                      *ngIf="!isLoading && platformPostStatus[provider]?.total === platformPostStatus[provider]?.failed">
                      <span>Fail</span>
                      <img src="../../../assets/images/status_fail.svg" alt="">
                    </ng-container>

                    <ng-container *ngIf="!isLoading && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.success
                    && platformPostStatus[provider]?.total !== platformPostStatus[provider]?.failed">
                      <span>Warning</span>
                      <img src="../../../assets/images/status_warning.svg" alt="">
                    </ng-container>

                  </div>
                </div>
                <div class="platform_progressbar">
                  <div class="platform_progressbar_in">
                    <span></span>
                  </div>
                </div>
                <ng-container *ngIf="isSelectedAccountToggle === i">
                  <div class="selected_account_list" *ngFor="let account of this.selectedAccounts">
                    <div class="selected_account_list_in" *ngIf="account.provider === provider">
                      <div class="left_sec">
                        <app-image-preview [imageUrl]="account.image" [class]="'preview-post-img'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="right_sec">
                        <div class="right_upper">
                          <div class="detl_sec">
                            <span>{{account.name}}</span>
                          </div>
                          <div class="platform_status">
                            <span>{{ postAccountStatus[account.id]?.status === true ? 'Completed' : 'Failed' }}</span>
                            <img
                              src=" {{ postAccountStatus[account.id]?.status ===true ? '../../../assets/images/status_complete.svg' : '../../../assets/images/status_fail.svg' }}"
                              alt="">
                          </div>
                        </div>
                        <div class="subplatform_progress">
                          <div
                            class="subplatform_progress_in {{ postAccountStatus[account.id]?.status ===true ? 'status_completed' : 'status_fail' }} ">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
            <div class="gen_error">
              <img src="../../../assets/images/complited_bulk_post.png" />
              <span>{{ postResponseError }}</span>
            </div>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="validationErrors.length!==0">
          <ng-container *ngFor="let provider of allAccountProvider; let i = index">
            <div class="platform_progress_in" *ngIf="platformPostStatus.hasOwnProperty(provider)"
              [class.post_warning]="!isLoading && platformPostStatus[provider]?.failed">
              <div class="icon_sec">
                <img *ngIf="provider === sharedService.facebookProvider" src="../../../assets/images/Facebook.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.twitterProvider" src="../../../assets/images/Twitter.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.instagramProvider" src="../../../assets/images/Instagram.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.youtubeProvider" src="../../../assets/images/YouTube.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.pinterestProvider" src="../../../assets/images/Pinterest.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.linkedinProvider" src="../../../assets/images/LinkedIn.png"
                  [alt]="provider">
                <img *ngIf="provider === sharedService.tiktokProvider" src="../../../assets/images/post_tiktok.png"
                  [alt]="provider">
              </div>
              <div class="detail_sec">
                <div class="title_sec">
                  <a class="btn_toggle" href="javascript:;" (click)="isToggle(i)"
                    [class.disabled_while_loading]="isLoading" [class.selected]="isSelectedAccountToggle === i">
                    <!-- <span nz-icon nzType="down" nzTheme="outline"></span> -->
                    <img src="../../../assets/images/down_arrow.svg" alt="">
                  </a>
                  <h5>{{ sharedService.platformName(provider) | titlecase }}</h5>

                  <div class="post_status_main">
                    <ng-container *ngIf="!isLoading && platformPostStatus[provider]?.failed">
                      <span>Validation Error</span>
                      <img src="../../../assets/images/status_warning.svg" alt="">
                    </ng-container>

                  </div>
                </div>
                <div class="platform_progressbar">
                  <div class="platform_progressbar_in">
                    <span></span>
                  </div>
                </div>
                <ng-container *ngIf="isSelectedAccountToggle === i">
                  <div class="selected_account_list" *ngFor="let account of this.selectedAccounts">
                    <div class="selected_account_list_in" *ngIf="account.provider === provider">
                      <div class="left_sec">
                        <app-image-preview [imageUrl]="account.image" [class]="'preview-post-img'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="right_sec">
                        <div class="right_upper">
                          <div class="detl_sec">
                            <span>{{account.name}}</span>
                          </div>
                          <div class="platform_status">
                            <span>{{ postAccountStatus[account.id]?.status === true ? '' : 'Validation Error' }}</span>
                            <img src="../../../assets/images/status_warning.svg" alt="">
                          </div>
                        </div>
                        <div class="subplatform_progress">
                          <div class="subplatform_progress_in status_warning "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </div>
    </div>


    <div class="posting_footer">
      <!-- Footer warning-->

      <ng-container *ngIf="validationErrors.length == 0">
        <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
          <!--post-->
          <ng-container *ngIf="loadingPostType==='post'">
            <p *ngIf="isLoading">Sit tight! We'll notify you as soon as your post has been successfully shared!</p>
            <p *ngIf="!isLoading">Feel free to close this window. Your content is now live on all selected platforms.
            </p>
          </ng-container>

          <!--schedule-->
          <ng-container *ngIf="loadingPostType==='schedule' || loadingPostType==='repeat'">
            <p *ngIf="isLoading">Sit tight! We'll notify you as soon as your post has been successfully scheduled!</p>
            <p *ngIf="!isLoading">Feel free to close this window. Your content is now scheduled on all selected
              platforms.</p>
          </ng-container>

          <!--draft-->
          <ng-container *ngIf="loadingPostType==='draft'">
            <p *ngIf="isLoading">Sit tight! We'll notify you as soon as your post has been successfully drafted!</p>
            <p *ngIf="!isLoading">Feel free to close this window. Your content is now drafted on all selected platforms.
            </p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
          <p>You need to fix the issue before process</p>
        </ng-container>

      </ng-container>
      <ng-container *ngIf="validationErrors.length > 0">
        <p>Validation failed please resolve and try again</p>
      </ng-container>




      <!-- Footer buttons-->
      <div class="btn_sec btn_posting_wrapper">
        <ng-container *ngIf="validationErrors.length===0">

          <ng-container *ngIf="isLoading || (!isLoading && countKeys(platformPostStatus)>0)">
            <button class="btn_primary" *ngIf="postFailed.length>0" (click)="tryAgainPost()">
              Try Again
            </button>

            <button class="btn_primary" [disabled]="isLoading" (click)="closeWindow()">
              Close Window
            </button>

            <button class="btn_primary" [disabled]="isLoading" (click)="viewPost()" *ngIf="postFailed.length==0">
              View Post
            </button>
          </ng-container>

          <ng-container *ngIf="!isLoading &&  countKeys(platformPostStatus) === 0">
            <button class="btn_primary" (click)="tryAgainPost()">
              Try Again
            </button>
            <button class="btn_primary" [disabled]="isLoading" (click)="closeWindow()">
              Close Window
            </button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="validationErrors.length>0">
          <button class="btn_primary" (click)="resolveError()">
            Resolve Error
          </button>
          <button class="btn_primary" [disabled]="isLoading" (click)="closeWindow()">
            Close Window
          </button>
        </ng-container>

      </div>

    </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isShowLocation" nzClassName="user_location_modal" nzMaskClosable="false" nzTitle="Add Location" (nzOnCancel)="hideLocationModal()" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="title_area">
      <p>Posts with a tagged location result in 79% higher engagement than posts without a tagged location</p>
    </div>
    <div class="search_location_area">
      <!-- <div class="user_info">
        <app-image-preview >
         <img src="https://basal-social-dev.s3.us-east-2.amazonaws.com/inbox/user-33/1696589189.589298.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATYI4QHWZVC4ECPRH%2F20240709%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240709T101555Z&X-Amz-Expires=86400&X-Amz-Signature=16329a60f10145d7b904528697f72bee36a8ededab6dca6003d08fee5a6f5e40&X-Amz-SignedHeaders=host" alt="">
        </app-image-preview>
        <app-provider>
          <img src="../../../assets/images/Facebook.png" alt="">
        </app-provider>
      </div> -->
      <div class="location_input">
        <nz-select nzShowSearch nzAllowClear nzServerSearch nzPlaceHolder="Search for location"
                   nzDropdownClassName="team_social_networks"
                   [(ngModel)]="selectedLocation" (nzOnSearch)="facebookLocationSearch($event)">

          <nz-option *ngIf="isLocationLoading" nzDisabled nzCustomContent>
            <span nz-icon nzType="loading" class="loading-icon"></span>
            Searching...
          </nz-option>

          <ng-container *ngFor="let location of postLocations">
            <nz-option  *ngIf="!isLocationLoading" nzCustomContent [nzLabel]="location.name" [nzValue]="location">
              <h6>{{ location.name }}
                <img *ngIf="location.is_verified"
                     src="../../../assets/images/blue_verified.svg"
                     alt="" style="width:15px" />
              </h6>
              <span>{{ location.address }}</span>
            </nz-option>
          </ng-container>

        </nz-select>
      </div>
    </div>
    <div class="btn_location">
      <button type="button" class="btn_cancel" (click)="hideLocationModal()">Cancel</button>
      <button type="button" class="btn_primary" (click)="selectLocation()">Submit</button>
    </div>
  </ng-container>
</nz-modal>

<!--
<nz-modal [(nzVisible)]="isImageGenerateModal" nzClassName="ai_generation_modal" nzMaskClosable="false" nzTitle="Select Media"
(nzOnCancel)="closeImageGenerateModal()" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="title_area">
      <p>Write a text to generate Image</p>
    </div>

    <div class="ai_generation_input">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input type="text" nz-input placeholder="Write a prompt" [(ngModel)]="textToImage"  [disabled]="isImageGenerating" />
        <a href="javascript:;" class="clearAiInput" *ngIf="textToImage.length != 0" (click)="clearAiInput()" [class.disabled_while_loading]="isImageGenerating">
          <span nz-icon nzType="close-circle" nzTheme="fill"></span>
        </a>
      </nz-input-group>
      <ng-template #suffixButton>
        <button nz-button nzType="primary" nzSize="large" nzSearch (click)="generateImages()"
        [disabled]="textToImage.length==0 || isImageGenerating">Generate</button>
      </ng-template>
    </div>

    <div>
      <ng-container *ngIf="isImageGenerating">
        <div class="ai_generation_skeleton">
          <ng-container  *ngFor="let img of dummyImages">
            <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="generatedImages.length <= 0 && !isImageGenerating">
        <div class="default_ai_message">
          <img src="../../../assets/images/search-ai-image.gif" alt="">
          <p>Struggling to find the perfect image? We've got you covered! Our tool helps you generate exactly what you need.</p>
        </div>
      </ng-container>

      <ng-container *ngIf="!isImageGenerating">
        <div class="ai_img_grid">
          <nz-checkbox-wrapper (nzOnChange)="generatedImagesEvent($event)">
            <div *ngFor="let img of generatedImages">
              <label nz-checkbox [nzValue]="img">
                <img src="data:image/png;base64,{{ img }}" alt="Generated Image">
              </label>
            </div>
          </nz-checkbox-wrapper>
        </div>
      </ng-container>
    </div>

    <div class="btn_ai_generation">
      <button type="button" class="btn_primary_outline" (click)="closeImageGenerateModal()"  [disabled]="isImageGenerating">Cancel</button>
      <button type="button" class="btn_primary" (click)="uploadGeneratedImages()" [disabled]="selectedGeneratedImage?.length <= 0">Use Media</button>
    </div>
  </ng-container>
</nz-modal>-->

<nz-modal [(nzVisible)]="isImageGenerateModal" nzClassName="ai_image_prompt" nzMaskClosable="false" (nzOnCancel)="closeaiImagePrompt()" [nzFooter]="null">
  <ng-container *nzModalContent>
    <div class="ai_img_prompt">
      <div class="ai_img_prompt_left">

        <div class="title_sec">
            <h2>AI Image Generator</h2>
            <div class="po">
               Powered By: <img src="../../../assets/images/amazon-bedrock.svg" alt=""><strong> AWS Bedrock</strong>
            </div>
          <!--<div class="generation_count">
            <div>
              <strong>96 / 100 generations</strong>
              <span>
                Auto-refill:
                <a href="javascript:;">
                  On
                  <img src="../../../assets/images/question-mark-circle-outline.svg" alt="">
                </a>
              </span>
            </div>
          </div>-->
        </div>

        <div class="ai_prompt_form">
          <label>Prompt</label>
          <div class="input_area" [class.disabled]="isImageGenerating || isImageRegenerating">
            <textarea placeholder="Enter your prompt here…" [(ngModel)]="textToImage"
                       maxlength="500" [disabled]="isImageGenerating || isImageRegenerating"></textarea>
            <span>{{textToImage.length}}/500</span>
          </div>
          <div class="action_area">
            <button class="btn_primary" (click)="generateImages()"
                    [disabled]="textToImage.length==0 || (isImageGenerating || isImageRegenerating)">
              <img src="../../../assets/images/generate-icon.svg" alt="">
              <span>Generate</span>
            </button>
          </div>
        </div>

        <div class="ai_style_selection">
          <div class="title_sec">
            <h5>Style Selector</h5>
          </div>

          <nz-radio-group [(ngModel)]="styleSelection">
            <label nz-radio *ngFor="let style of styles" [nzValue]="style.value" [nzDisabled]="isImageGenerating || isImageRegenerating">
              <div class="ai_style_radio">
                <div class="img_sec">
                  <img [src]="'../../../assets/images/style-selector/'+style.imageUrl" alt="style">
                </div>
                <span>{{ style.name }}</span>
              </div>
            </label>
          </nz-radio-group>
        </div>

        <div class="ai_style_ratio">
          <div class="title_sec">
            <h5>Aspect Ratio</h5>
          </div>
          <nz-radio-group [(ngModel)]="styleAspectRatio">
            <label nz-radio nzValue="1:1" [nzDisabled]="isImageGenerating || isImageRegenerating">
              <div class="ai_ratio_radio">
                <span class="ratio_icon_1_1"></span>
                <span>1:1</span>
              </div>
            </label>
            <label nz-radio nzValue="16:9" [nzDisabled]="isImageGenerating || isImageRegenerating">
              <div class="ai_ratio_radio">
                <span class="ratio_icon_16_9"></span>
                <span>16:9</span>
              </div>
            </label>
            <label nz-radio nzValue="4:5" [nzDisabled]="isImageGenerating || isImageRegenerating" *ngIf="!allAccountProvider.includes(sharedService.instagramProvider)">
              <div class="ai_ratio_radio">
                <span class="ratio_icon_9_16"></span>
                <span>4:5</span>
              </div>
            </label>
          </nz-radio-group>
        </div>

      </div>

      <div class="ai_img_prompt_right">
        <!-- Title -->
        <div class="title_message">
          <div class="img_sec">
            <img src="../../../assets/images/describe_ai_image.png" alt="">
          </div>
          <div class="des_sec">
            <strong>Describe Your Image</strong>
            <p>Enter a prompt to start creating stunning visuals. Be as detailed as you like!</p>
          </div>
        </div>

        <!-- Results -->
        <div class="default_result" *ngIf="generatedImages.length === 0 && !isImageGenerating">
          <div class="default_result_hdr">
            <h5>
              <img src="../../../assets/images/ai-image.svg" alt="">
              <span>Results</span>
            </h5>
          </div>
          <div class="default_result_body">
            <img src="../../../assets/images/ai-image.svg" alt="">
          </div>
        </div>

        <!-- Generating -->
        <div class="default_result" *ngIf="isImageGenerating">
          <div class="default_result_hdr">
            <h5>
              <img src="../../../assets/images/ai-image.svg" alt="">
              <span>Generating...</span>
            </h5>
            <!--<span>33/100 steps | 6.1/14.2s</span>-->
          </div>
          <div class="default_result_body">
            <div class="generating_message">
              <div class="message_wrapper">
                <img src="../../../assets/images/painting-default.svg" alt="">
                <h5>Creating Your Masterpiece</h5>
                <p>This will only take a moment...</p>
                <!-- <strong>33.0%</strong> -->
              </div>
              <div class="message_progress">
                <div class="progress-wrapper">
                  <span class="progress-bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Select an Image -->
        <div class="ai_img_selection" *ngIf="generatedImages.length > 0 && !isImageGenerating">
          <div class="title_sec">
            <h5>
              <img src="../../../assets/images/ai-image.svg" alt="">
              <span>Select an Image</span>
            </h5>
          </div>

          <div class="ai_img_check">
            <nz-checkbox-wrapper (nzOnChange)="generatedImagesEvent($event)">
              <label nz-checkbox *ngFor="let img of generatedImages" [nzValue]="img" [nzDisabled]="isImageRegenerating">
                <div class="img_sec">
                  <a href="javascript:;" class="img_preview_icon" (click)="viewGeneratedImage(img)">
                    <img src="../../../assets/images/info_icon_filled.svg" alt="">
                  </a>
                  <ng-container *ngIf="isImageRegenerating">
                    <ng-container *ngIf="selectedGeneratedImage != img">
                      <div class="ai_img_skeleton">
                        <nz-skeleton [nzActive]="true"></nz-skeleton>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedGeneratedImage == img">
                      <img src="data:image/png;base64,{{ img }}" alt="Generated Image">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!isImageRegenerating">
                    <img src="data:image/png;base64,{{ img }}" alt="Generated Image">
                  </ng-container>

                </div>
              </label>
            </nz-checkbox-wrapper>
            <div class="po">
              Powered By: <img src="../../../assets/images/amazon-bedrock.svg" alt="">
              <strong>
              AWS Bedrock</strong>
            </div>
          </div>

          <div class="ai_img_input" *ngIf="needRegenerate">
            <div class="input_area">
              <label>Refined AI Prompt</label>
              <textarea placeholder="Enter your prompt here…" [(ngModel)]="refineTextToImage"
                        [class.disabled_while_loading]="isImageRegenerating" maxlength="500"></textarea>
            </div>
            <span>{{refineTextToImage.length}}/500</span>
            <div class="ai_img_input_action">
              <button class="btn_primary" (click)="generateVariantImage()" [class.disabled_while_loading]="selectedGeneratedImage.length == 0 || refineTextToImage == '' || isImageRegenerating">
                <img src="../../../assets/images/regenerate.svg" alt="">
                Refine Image
              </button>
            </div>
          </div>


          <div class="ai_img_action">
            <button type="button" (click)="needRegenerate = !needRegenerate" [class.disabled_while_loading]="selectedGeneratedImage.length!==1 || isImageRegenerating"
            [class.cancelRegeneration]="needRegenerate">
              <ng-container *ngIf="!needRegenerate">
                <div class="icon_sec">
                  <img src="../../../assets/images/regenerate.svg" alt="">
                </div>
                <span>Regenerate</span>
              </ng-container>
              <ng-container *ngIf="needRegenerate">
                <div class="icon_sec">
                  <img src="../../../assets/images/status_fail.svg" alt="">
                </div>
                <span>Cancel</span>
              </ng-container>
            </button>
            <button type="button" (click)="uploadGeneratedImages()" [disabled]="selectedGeneratedImage.length===0">
              <div class="icon_sec">
                <img src="../../../assets/images/share.svg" alt="">
              </div>
              <span>Share</span>
            </button>
            <button type="button" (click)="downloadGeneratedImages()" [disabled]="selectedGeneratedImage.length===0">
              <div class="icon_sec">
                <img src="../../../assets/images/save-ai-image.svg" alt="">
              </div>
              <span>Save</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </ng-container>
</nz-modal>

<!-- Hashtag Generate Start -->
<nz-modal nzClassName="hashTagDetectionModal" [(nzVisible)]="isShowHashTagGenerate" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="header_main">
      <h4>
        <img src="../../../assets/images/hashtagDetection.svg" alt="">
        <span>Hashtag Detection</span>
      </h4>
      <a href="javascript:;" (click)="hasTagGenerationCloseModal()">
        <img src="../../../assets/images/close-darkblue.svg" alt="">
      </a>
    </div>

    <div class="detection_warning">
      <div class="img_sec">
        <img src="../../../assets/images/describe_ai_image.png" alt="">
      </div>
      <div class="dtl_sec">
        <h5>Easily generate relevant hashtags from your images</h5>
        <p>Our AI-powered tool automatically detects and suggests tags based on your image to enhance your post’s visibility and engagement across all platforms.</p>
      </div>
    </div>

  <div class="img_detection_area">
    <div class="img_sec">
      <app-image-preview [isVideo]="isVideoHashTag" [imageUrl]="hashTagImg" ></app-image-preview>
    </div>
    <div class="tags_main">
      <ng-container *ngIf="!isHashTagLoading">
        <div class="tags_in">
          <div class="result_title">
            <h5>Results</h5>
            <div class="po">
               Powered By: <img src="../../../assets/images/aws_rekognition.png" alt="">
               <strong>AWS Rekognition</strong>
            </div>
          </div>
          <div class="tags_selection">
            <perfect-scrollbar style="max-height: 490px;">
              <nz-checkbox-wrapper (nzOnChange)="getHashTags($event)">
                <ul>
                  <li *ngFor="let hashtag of hashtags">
                    <label nz-checkbox nzValue="#{{ hashtag.Name }}">
                      <div class="tags_label">
                        <div class="label_sec">
                          <h4>#{{ hashtag.Name }}</h4>
                        </div>
                        <div class="percentage">
                          <span>{{ hashtag.Confidence | number:'1.2-2' }}%</span>
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </nz-checkbox-wrapper>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="btn_sec">
          <button type="button" class="btn_primary" [class.disabled_while_loading]="selectedHashtags.length == 0" (click)="addHashtagsToPost()">Add Hashtags to Post</button>
        </div>
      </ng-container>
      <ng-container *ngIf="isHashTagLoading">
        <div class="hash_tag_skeleton" *ngFor="let item of dummyItems">
          <nz-skeleton [nzAvatar]="false" [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
          <nz-skeleton [nzAvatar]="false" [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
        </div>
      </ng-container>
    </div>
  </div>
  </ng-container>
</nz-modal>
<!-- Hashtag Generate End -->

<!-- Preview of AI Generated image -->
<nz-modal nzClassName="preGeneratedImage" [(nzVisible)]="isPreviewGeneratedImageModal" [nzFooter]=null >
  <ng-container *nzModalContent>
    <a href="javascript:;" class="closeModal" (click)="isPreviewGeneratedImageModal=false">
      <img src="../../../assets/images/modal_close.svg" alt="">
    </a>
    <div>
      <img src="data:image/png;base64,{{ previewGeneratedImage }}" alt="Generated Image">
    </div>
  </ng-container>
</nz-modal>
<!-- Preview of AI Generated image -->
